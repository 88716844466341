import React, { useEffect, useState } from "react";
import { FaArrowLeft, FaPencilAlt, FaEye } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";

import { Col, Row, Button, Form, FormGroup } from "reactstrap";

import Header from "../../components/Header/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import Footer from "../../components/Footer/Footer";
import Loader from "../../components/shared/Loader";
import Message from "../../components/shared/Alerts";
import SnackBar from "../../components/shared/Snackbar";
import { useDispatch, useSelector } from "react-redux";
import api from "../../api/api";
import s from "../../components/Layout/Layout.module.scss";
import { getNoticiaDetails } from "../../redux/actions/noticiasActions";
import Widget from "../../components/Widget/Widget";
import TextField from "@mui/material/TextField";

import { useForm } from "react-hook-form";
import Seo from "../../components/shared/seo";
const NoticiasEditpage = () => {
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);
  const { id } = useParams();
  const {
    register,
    handleSubmit,

    setValue,

    formState: { errors },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    criteriaMode: "firstError",
    shouldFocusError: true,
  });
  const onSubmit = (data) => {
    console.log(data.etiqueta);

    const edit = async () => {
      setLoading(true);
      setError(null);
      setSuccess(null);
      try {
        const { res } = await api.post("/noticia/actualizar", {
          id,
          titulo: data.titulo,
          url: data.url,
          descripcion: data.descripcion,
          etiqueta: data.etiqueta,
        });
        setSuccess(true);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setError("Algo salió mal.");
        setLoading(false);
      }
    };

    edit();
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const detalleInfo = useSelector((state) => state.noticiaDetalle);
  const { loading: loadingDetalle, error: errorDetalle, noticia } = detalleInfo;
  const [message, setMessage] = useState(null);

  const handleRegresar = (e) => {
    e.preventDefault();
    navigate(-1);
  };
  const noticiasHandler = (e) => {
    e.preventDefault();
    navigate("/noticias");
  };

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    }
    const getDefault = () => {
      if (noticia) {
      } else {
        dispatch(getNoticiaDetails(id));
      }
    };
    getDefault();
  }, [userInfo, navigate, dispatch, id, noticia, setValue, success]);

  return (
    <div className={s.root}>
      <Seo
        titlePage="Noticias"
        descriptionPage="Pádel Cuenca. Somos un club conformado por personas amantes del deporte, enfocados en fomentar la vida activa y saludable, priorizando el ejercicio como un hábito que beneficie nuestro estado físico y mental, Disponemos de canchas de Pádel en Cuenca Ecuador para que pueda realizar una reserva."
      />
      <div className={s.wrap}>
        <Header />

        <Sidebar />
        <main className={s.content}>
          {message && (
            <SnackBar
              severity="error"
              message="Por favor actualize uno de los valores"
            />
          )}
          <Row>
            <Col>
              <button
                className="tg-btn rounded-pill my-3 body-2 "
                style={{
                  background: "#41404B",
                  color: "white",
                  marginTop: "5px",
                  padding: "5px 10px",
                  fontSize: "16px",
                }}
                onClick={handleRegresar}
              >
                <FaArrowLeft /> Regresar
              </button>
              <Row className="mb-12">
                <Col xs={12} xl={12}>
                  <Widget>
                    {" "}
                    <div style={{ minHeight: "300px", padding: "20px" }}>
                      {" "}
                      <div className="headline-2">Editar Noticia</div>
                      {error && (
                        <Message
                          message="Algo salió mal. Por favor intentalo más tarde"
                          severity="error"
                        />
                      )}
                      {loading && (
                        <div style={{ textAlign: "center" }}>
                          {" "}
                          <Loader />
                        </div>
                      )}
                      {success && (
                        <div style={{ textAlign: "center", marginTop: "25px" }}>
                          <Message
                            message="Noticia Actualizada con éxito"
                            severity="success"
                          />
                          <div>
                            <Button
                              className="rounded-pill my-3 body-2 "
                              color="primary"
                              style={{ color: "white" }}
                              type="submit"
                              onClick={(e) => noticiasHandler(e)}
                            >
                              <FaEye /> Ver todas las noticias
                            </Button>
                          </div>
                        </div>
                      )}
                      {errorDetalle ? (
                        <Message
                          message="Algo salió mal. Por favor intentalo más tarde"
                          severity="error"
                        />
                      ) : loadingDetalle ? (
                        <Loader />
                      ) : (
                        noticia && (
                          <div style={{ marginTop: "25px", maxWidth: "700px" }}>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                              {/*  <Label
                                  for="exampleEmail"
                                  style={{ minWidth: "100px" }}
                                >
                                  Título
                                </Label> */}
                              <TextField
                                id="outlined-textarea"
                                label="Título"
                                multiline
                                style={{
                                  minWidth: "330px",
                                }}
                                rows={1}
                                {...register("titulo")}
                                defaultValue={noticia.titulo}
                              />

                              <FormGroup>
                                <TextField
                                  {...register("url", {})}
                                  id="outlined-textarea"
                                  type="text"
                                  label="Url"
                                  multiline
                                  style={{
                                    minWidth: "330px",
                                    marginTop: "25px",
                                  }}
                                  rows={5}
                                  defaultValue={noticia.url}
                                />
                              </FormGroup>
                              <FormGroup>
                                <TextField
                                  {...register("etiqueta", {})}
                                  id="outlined-textarea"
                                  type="text"
                                  label="Etiqueta"
                                  multiline
                                  style={{
                                    minWidth: "330px",
                                    marginTop: "25px",
                                  }}
                                  rows={1}
                                  defaultValue={noticia.etiqueta}
                                />
                              </FormGroup>
                              <FormGroup>
                                <TextField
                                  {...register("descripcion", {})}
                                  id="outlined-textarea"
                                  type="text"
                                  label="Descripción"
                                  multiline
                                  style={{
                                    minWidth: "330px",
                                    marginTop: "25px",
                                  }}
                                  rows={5}
                                  defaultValue={noticia.descripcion}
                                />
                              </FormGroup>
                              <Button
                                className="rounded-pill my-3 body-2 "
                                color="warning"
                                style={{ color: "white" }}
                                type="submit"
                              >
                                <FaPencilAlt /> Editar
                              </Button>
                            </Form>
                          </div>
                        )
                      )}
                    </div>
                  </Widget>
                </Col>
              </Row>
            </Col>
          </Row>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default NoticiasEditpage;
