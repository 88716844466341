import React, { useEffect, useState } from "react";
import { FaArrowLeft, FaPencilAlt, FaEye } from "react-icons/fa";
import {
  BrowserRouter as Router,
  useLocation,
  useNavigate,
  useParams,
  Link,
} from "react-router-dom";

import { Col, Row, Button, Form, FormGroup, Label, Input } from "reactstrap";

import Header from "../../components/Header/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import Footer from "../../components/Footer/Footer";
import Loader from "../../components/shared/Loader";
import Message from "../../components/shared/Alerts";
import SnackBar from "../../components/shared/Snackbar";
import { useDispatch, useSelector } from "react-redux";
import api from "../../api/api";

// -- Component Styles
import s from "../../components/Layout/Layout.module.scss";
import { getDescuentoDetails } from "../../redux/actions/horariosActions";
import Widget from "../../components/Widget/Widget";
import TextField from "@mui/material/TextField";

import { useForm } from "react-hook-form";
import Seo from "../../components/shared/seo";
const HorarioEditpage = () => {
  const [precios, setPrecios] = useState(null);
  const [descuentos, setDescuentos] = useState(null);
  const [newPrecio, setNewPrecio] = useState(null);
  console.log(newPrecio);
  const [newDescuento, setNewDescuento] = useState(null);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);
  const [successInfo, setSuccessInfo] = useState(null);
  const [errorInfo, setErrorInfo] = useState(null);
  const [loadingInfo, setLoadingInfo] = useState(null);
  const { dia, hora, precio, descuento } = useParams();
  console.log(dia);
  /*  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    criteriaMode: "firstError",
    shouldFocusError: true,
  }); */
  const onSubmit = () => {
    setMessage(null);
    if (window.confirm("Desea modificar los precios de este horario ?")) {
      if (newPrecio && newDescuento) {
        setLoading(true);
        setError(null);
        setSuccess(null);
        const edit = async () => {
          try {
            const { res } = await api.post(
              "/horario-cancha/actualizar/precio",
              [
                {
                  dia,
                  hora,
                  precio: newPrecio,
                  descuento: newDescuento,
                },
              ]
            );
            navigate("/precios/administrar");
            setSuccess(true);
            setLoading(false);
          } catch (error) {
            //console.log(error);
            setError("Algo salió mal.");
            setLoading(false);
          }
        };
        edit();
      } else {
        setMessage("Por favor seleccione un nuevo precio y descuento.");
      }
    }
  };

  const navigate = useNavigate();

  let location = useLocation();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const detalleInfo = useSelector((state) => state.descuentoDetalle);
  /*  const {
    loading: loadingDetalle,
    error: errorDetalle,
    descuento,
  } = detalleInfo; */
  const [message, setMessage] = useState(null);

  const handleRegresar = (e) => {
    e.preventDefault();
    navigate(-1);
  };
  /* const noticiasHandler = (e) => {
    e.preventDefault();
    navigate("/horarios/precios");
  }; */

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    }
    const getDefault = async () => {
      setLoadingInfo(true);
      setErrorInfo(null);
      setSuccessInfo(null);
      try {
        const { data: dataPrecios } = await api.get("/precio", {});
        //console.log(dataPrecios);
        const { data: dataDescuentos } = await api.get("/descuento", {});
        setPrecios(dataPrecios);
        setDescuentos(dataDescuentos);
        setSuccessInfo(true);
        setLoadingInfo(false);
      } catch (error) {
        //console.log(error);
        setErrorInfo("Algo salió mal.");
        setLoadingInfo(false);
      }
      /*  if (descuento) {
      } else {
        //dispatch(getDescuentoDetails(id));
      } */
    };
    getDefault();
  }, [userInfo, navigate, dispatch, dia, descuento]);

  return (
    <div className={s.root}>
      <Seo
        titlePage="Precios"
        descriptionPage="Pádel Cuenca. Somos un club conformado por personas amantes del deporte, enfocados en fomentar la vida activa y saludable, priorizando el ejercicio como un hábito que beneficie nuestro estado físico y mental, Disponemos de canchas de Pádel en Cuenca Ecuador para que pueda realizar una reserva."
      />
      <div className={s.wrap}>
        <Header />

        <Sidebar />
        <main className={s.content}>
          {message && (
            <SnackBar
              severity="error"
              message="Por favor seleccione una opción para  actualizar el precio y descuento."
            />
          )}
          <Row>
            <Col>
              <button
                className="tg-btn rounded-pill my-3 body-2 "
                style={{
                  background: "#41404B",
                  color: "white",
                  marginTop: "5px",
                  padding: "5px 10px",
                  fontSize: "16px",
                }}
                onClick={handleRegresar}
              >
                <FaArrowLeft /> Regresar
              </button>
              <Row className="mb-12">
                <Col xs={12} xl={12}>
                  <Widget>
                    {" "}
                    <div style={{ minHeight: "300px", padding: "20px" }}>
                      {" "}
                      {/*  <div className="headline-2">
                        Editar Precio y Descuento
                      </div> */}
                      <div className="headline-2" style={{ fontSize: "25px" }}>
                        Día: <span style={{ color: "#8dbe22" }}>{dia}</span>{" "}
                        Hora: <span style={{ color: "#8dbe22" }}>{hora}</span>
                      </div>
                      <div className="headline-2">
                        Precio Normal Actual:{" "}
                        <span style={{ color: "#8dbe22" }}>$ {precio}</span>{" "}
                      </div>
                      <div className="headline-2">
                        Precio Socio Actual:{" "}
                        <span style={{ color: "#8dbe22" }}>
                          $ {precio - descuento}
                        </span>
                      </div>
                      <hr style={{ border: "1px solid rgba(0,0,0,0.5)" }} />
                      <div className="headline-2">Editar nuevos valores</div>
                      {error && (
                        <Message
                          message="Algo salió mal. Por favor intentalo más tarde"
                          severity="error"
                        />
                      )}
                      {loading && (
                        <div style={{ textAlign: "center" }}>
                          {" "}
                          <Loader />
                        </div>
                      )}
                      {success && (
                        <div style={{ textAlign: "center", marginTop: "25px" }}>
                          <Message
                            message="Descuento Actualizado con éxito"
                            severity="success"
                          />
                          {/*  <div>
                            <Button
                              className="rounded-pill my-3 body-2 "
                              color="primary"
                              style={{ color: "white" }}
                              type="submit"
                              onClick={(e) => noticiasHandler(e)}
                            >
                              <FaEye /> Ver todos los precios
                            </Button>
                          </div> */}
                        </div>
                      )}
                      {errorInfo ? (
                        <Message
                          message="Algo salió mal. Por favor intentalo más tarde"
                          severity="error"
                        />
                      ) : loadingInfo ? (
                        <Loader />
                      ) : (
                        successInfo && (
                          <div style={{ marginTop: "25px", maxWidth: "700px" }}>
                            <Form>
                              {precios && (
                                <FormGroup inline>
                                  <Label
                                    for="exampleSelect"
                                    style={{
                                      fontSize: "18px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    Precios
                                  </Label>
                                  <Input
                                    id="exampleSelect"
                                    name="select"
                                    type="select"
                                    multiple
                                    onChange={(e) => {
                                      setNewPrecio(e.target.value);
                                    }}
                                  >
                                    {precios.map((item, index) => (
                                      <option key={index} value={item.id}>
                                        {item.nombre} - ${item.precio}
                                      </option>
                                    ))}
                                    {/* <option>1</option>
                                  <option>2</option>
                                  <option>3</option>
                                  <option>4</option>
                                  <option>5</option> */}
                                  </Input>
                                </FormGroup>
                              )}

                              {descuentos && (
                                <FormGroup inline>
                                  <Label
                                    style={{
                                      fontSize: "18px",
                                      fontWeight: "600",
                                    }}
                                    for="exampleSelect"
                                  >
                                    Descuentos
                                  </Label>
                                  <Input
                                    id="exampleSelect"
                                    name="select"
                                    type="select"
                                    multiple
                                    onChange={(e) => {
                                      setNewDescuento(e.target.value);
                                    }}
                                  >
                                    {descuentos.map((item, index) => (
                                      <option key={index} value={item.id}>
                                        {item.nombre} - ${item.descuento}
                                      </option>
                                    ))}
                                    {/* <option>1</option>
                                  <option>2</option>
                                  <option>3</option>
                                  <option>4</option>
                                  <option>5</option> */}
                                  </Input>
                                </FormGroup>
                              )}
                              <Button
                                className="rounded-pill my-3 body-2 "
                                color="warning"
                                style={{ color: "white" }}
                                onClick={onSubmit}
                              >
                                <FaPencilAlt /> Actualizar Precios
                              </Button>
                            </Form>
                          </div>
                        )
                      )}
                    </div>
                  </Widget>
                </Col>
              </Row>
            </Col>
          </Row>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default HorarioEditpage;
