import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
//import { withRouter } from "react-router";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Navbar,
  Nav,
  NavItem,
  NavLink,
  InputGroupAddon,
  InputGroup,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Form,
  FormGroup,
} from "reactstrap";

import { logout } from "../../redux/actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import {
  closeSidebar,
  openSidebar,
} from "../../redux/actions/navigationActions";
import MenuIcon from "../../images/Icons/HeaderIcons/MenuIcon";
import SearchBarIcon from "../../images/Icons/HeaderIcons/SearchBarIcon";
import SearchIcon from "../../images/Icons/HeaderIcons/SearchIcon";

import ProfileIcon from "../../images/assets/navbarMenus/pfofileIcons/ProfileIcon";
import MessagesIcon from "../../images/assets/navbarMenus/pfofileIcons/MessagesIcon";
import TasksIcon from "../../images/assets/navbarMenus/pfofileIcons/TasksIcon";

import logoutIcon from "../../images/assets/navbarMenus/pfofileIcons/logoutOutlined.svg";
import basketIcon from "../../images/assets/navbarMenus/basketIcon.svg";
import calendarIcon from "../../images/assets/navbarMenus/calendarIcon.svg";
import envelopeIcon from "../../images/assets/navbarMenus/envelopeIcon.svg";
import mariaImage from "../../images/assets/navbarMenus/mariaImage.jpg";
import notificationImage from "../../images/cancha.jpg";
import userImg from "../../images/perfil.png";

import s from "./Header.module.scss";
import "animate.css";
import { listReservasByCodigo } from "../../redux/actions/reservasActions";

import { listReservasNoRevisadas } from "../../redux/actions/reservasActions";
import { reservasNoRevisadosListReducer } from "../../redux/reducers/reservasReducers";

const Header = () => {
  const [searchKey, setSearchKey] = useState("");
  let location = useLocation();
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const dispatch = useDispatch();
  const navigator = useSelector((state) => state.navigation);
  //console.log(navigator);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const reservasNoRevisadasData = useSelector(
    (state) => state.reservasNorevisadas
  );
  const { loading, error, reservas } = reservasNoRevisadasData;

  const toggleNotifications = () => {
    setNotificationsOpen(!notificationsOpen);
  };

  const logoutHandler = () => {
    dispatch(logout());
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleSidebar = () => {
    if (navigator.sidebarOpened) {
      dispatch(closeSidebar());
    } else {
      const paths = location.pathname.split("/");
      paths.pop();
      dispatch(openSidebar());
    }
  };

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    }
    dispatch(listReservasNoRevisadas());
  }, [dispatch, userInfo, navigate]);

  return (
    <Navbar className={`${s.root} d-print-none`}>
      <div>
        <NavLink
          onClick={() => toggleSidebar()}
          className={`d-md-none mr-3 ${s.navItem}`}
          href="#"
        >
          <MenuIcon className={s.menuIcon} />
        </NavLink>
      </div>

      <Nav className="ml-auto">
        <NavItem className="d-sm-none mr-4">
          <NavLink className="" href="#">
            <SearchIcon />
          </NavLink>
        </NavItem>
        <Dropdown
          nav
          isOpen={menuOpen}
          toggle={() => toggleMenu()}
          className="tutorial-dropdown mr-2 mr-sm-3"
        >
          <DropdownToggle nav>
            <div className={s.navbarBlock}>
              <i className={"eva eva-bell-outline"} />
              {reservas
                ? reservas.numero > 0 && <div className={s.count}></div>
                : null}
            </div>
          </DropdownToggle>
          <DropdownMenu
            right
            className="navbar-dropdown notifications-dropdown"
            style={{ width: "340px" }}
          >
            <DropdownItem>
              {reservas
                ? reservas.numero > 0 && (
                    <>
                      {" "}
                      <img src={calendarIcon} alt="Calendar Icon" />
                      <span>{reservas.numero} nuevas reservas</span>
                    </>
                  )
                : null}
            </DropdownItem>
            {/*  <DropdownItem>
              <div>
                <div className="d-flex flex-row mb-1">
                  <img src={mariaImage} alt="Maria" className={s.mariaImage} />
                  <div className="d-flex flex-column">
                    <p className="body-3">Maria</p>
                    <p className="label muted">Hace 15 min</p>
                  </div>
                </div>
                <img
                  src={notificationImage}
                  alt="Notification Icon"
                  className={s.notificationImage}
                />
                <p className="body-2 muted">Ejemplo de imagen</p>
              </div>
            </DropdownItem> */}
            {reservas
              ? reservas.numeroRecargas > 0 && (
                  <DropdownItem>
                    {" "}
                    <img src={basketIcon} alt="Basket Icon" />
                    <span>{reservas.numeroRecargas} nuevas recargas</span>
                  </DropdownItem>
                )
              : null}
            {/*   <DropdownItem>
              <img src={calendarIcon} alt="Calendar Icon" />
              <span>1 recargas nuevas.</span>
            </DropdownItem> */}
            {/* <DropdownItem>
              <img src={envelopeIcon} alt="Envelope Icon" />
              <span>tiene nuevos mensajes</span>
            </DropdownItem> */}
          </DropdownMenu>
        </Dropdown>
        <Dropdown
          isOpen={notificationsOpen}
          toggle={() => toggleNotifications()}
          nav
          id="basic-nav-dropdown"
          className="ml-3"
        >
          <DropdownToggle nav caret className="navbar-dropdown-toggle">
            <span className={`${s.avatar} rounded-circle float-left mr-2`}>
              <img src={userImg} alt="User" />
            </span>
            <span className="small d-none d-sm-block ml-1 mr-2 body-1">
              {userInfo && userInfo.username}
            </span>
          </DropdownToggle>
          <DropdownMenu
            className="navbar-dropdown2 profile-dropdown"
            style={{ width: "194px" }}
          >
            {/*  <DropdownItem className={s.dropdownProfileItem}>
              <ProfileIcon />
              <span>Perfil</span>
            </DropdownItem> */}
            {/*   <DropdownItem className={s.dropdownProfileItem}>
              <TasksIcon />
              <span>Tareas</span>
            </DropdownItem>
            <DropdownItem className={s.dropdownProfileItem}>
              <MessagesIcon />
              <span>Mensajes</span>
            </DropdownItem> */}
            <NavItem>
              <NavLink onClick={() => logoutHandler()}>
                <button
                  className="btn btn-primary rounded-pill mx-auto logout-btn"
                  type="submit"
                >
                  <img src={logoutIcon} alt="Logout" />
                  <span className="ml-1">Salir</span>
                </button>
              </NavLink>
            </NavItem>
          </DropdownMenu>
        </Dropdown>
      </Nav>
    </Navbar>
  );
};

Header.propTypes = {
  dispatch: PropTypes.func.isRequired,
  sidebarOpened: PropTypes.bool,
};

/* function mapStateToProps(store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
    sidebarStatic: store.navigation.sidebarStatic,
  };
} */

export default Header;
