// -- React and related libs
import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { FaArrowLeft, FaPenAlt, FaPlus } from "react-icons/fa";
import {
  Col,
  Row,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Input,
} from "reactstrap";
import Widget from "../../components/Widget/Widget.js";
import { v4 as uuidv4 } from "uuid";
import Header from "../../components/Header/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import Footer from "../../components/Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/shared/Loader";
import Alert from "../../components/shared/Alerts";
// -- Component Styles
import s from "../../components/Layout/Layout.module.scss";
import sTable from "../../components/Tables/Tables.module.scss";
import mock from "../../components/Tables/mock";
import { listDescuentos } from "../../redux/actions/horariosActions";
import { DESCUENTO_DETALLE_RESET } from "../../redux/constants/horariosConstants";
import Seo from "../../components/shared/seo";
const DescuentosPage = () => {
  let location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const descuentosInfo = useSelector((state) => state.horariosDescuentos);
  const { loading, descuentos, error } = descuentosInfo;
  //console.log(location);

  const [secondTable] = useState(mock.secondTable);
  const [secondTableCurrentPage, setSecondTableCurrentPage] = useState(0);

  const pageSize = 10;
  const secondTablePagesCount = descuentos
    ? Math.ceil(descuentos.length / pageSize)
    : Math.ceil(secondTable.length / pageSize);

  const setSecondTablePage = (e, index) => {
    e.preventDefault();
    setSecondTableCurrentPage(index);
  };
  const handleRegresar = (e) => {
    e.preventDefault();
    navigate("/horarios");
  };

  const handleAgregar = (e) => {
    e.preventDefault();
    navigate("/horarios/descuentos/agregar");
  };
  const handleEditar = (id) => {
    navigate(`/horarios/descuentos/${id}`);
  };
  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    }
    dispatch({ type: DESCUENTO_DETALLE_RESET });
    dispatch(listDescuentos());
  }, [userInfo, navigate, dispatch]);

  return (
    <div className={s.root}>
      <Seo
        titlePage="Horarios"
        descriptionPage="Pádel Cuenca. Somos un club conformado por personas amantes del deporte, enfocados en fomentar la vida activa y saludable, priorizando el ejercicio como un hábito que beneficie nuestro estado físico y mental, Disponemos de canchas de Pádel en Cuenca Ecuador para que pueda realizar una reserva."
      />
      <div className={s.wrap}>
        <Header />
        <Sidebar />
        <main className={s.content}>
          <Row>
            <Col>
              <Row className="mb-4">
                <Col>
                  <button
                    className="tg-btn rounded-pill my-3 body-2 "
                    style={{
                      background: "#41404B",
                      color: "white",
                      marginTop: "5px",
                      padding: "5px 10px",
                      fontSize: "16px",
                    }}
                    onClick={handleRegresar}
                  >
                    <FaArrowLeft /> Regresar
                  </button>
                  <Widget>
                    <div className={sTable.tableTitle}>
                      <div className="headline-2">Descuentos por horarios</div>
                    </div>
                    {error ? (
                      <Alert message={error} severity="error" />
                    ) : loading ? (
                      <div style={{ textAlign: "center" }}>
                        <Loader />
                      </div>
                    ) : (
                      descuentos && (
                        <div className="widget-table-overflow">
                          <Table
                            className="table-striped table-borderless table-hover"
                            responsive
                          >
                            <thead>
                              <tr>
                                {/*  <th>
                              <div className="checkbox checkbox-primary">
                                <input
                                  id="checkbox200"
                                  className="styled"
                                  type="checkbox"
                                />
                                <label for="checkbox200" />
                              </div>
                            </th> */}
                                <th className={s.nameCol}>nombre</th>
                                <th className={s.nameCol}>descuento</th>

                                <th>editar</th>
                              </tr>
                            </thead>
                            <tbody>
                              {descuentos
                                .slice(
                                  secondTableCurrentPage * pageSize,
                                  (secondTableCurrentPage + 1) * pageSize
                                )
                                .map((item) => (
                                  <tr key={uuidv4()}>
                                    <td>{item.nombre}</td>
                                    <td>$ {item.descuento}</td>

                                    <td>
                                      <button
                                        style={{
                                          background: "#ffc405",
                                          padding: "5px",
                                          color: "white",
                                          border: "2px solid #ffc405",
                                        }}
                                        onClick={() => handleEditar(item.id)}
                                      >
                                        <span>
                                          <FaPenAlt /> Editar
                                        </span>
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </Table>
                          <Pagination className="pagination-with-border">
                            <PaginationItem
                              disabled={secondTableCurrentPage <= 0}
                            >
                              <PaginationLink
                                onClick={(e) =>
                                  setSecondTablePage(
                                    e,
                                    secondTableCurrentPage - 1
                                  )
                                }
                                previous
                                href="#top"
                              />
                            </PaginationItem>
                            {[...Array(secondTablePagesCount)].map(
                              (page, i) => (
                                <PaginationItem
                                  active={i === secondTableCurrentPage}
                                  key={i}
                                >
                                  <PaginationLink
                                    onClick={(e) => setSecondTablePage(e, i)}
                                    href="#top"
                                  >
                                    {i + 1}
                                  </PaginationLink>
                                </PaginationItem>
                              )
                            )}
                            <PaginationItem
                              disabled={
                                secondTableCurrentPage >=
                                secondTablePagesCount - 1
                              }
                            >
                              <PaginationLink
                                onClick={(e) =>
                                  setSecondTablePage(
                                    e,
                                    secondTableCurrentPage + 1
                                  )
                                }
                                next
                                href="#top"
                              />
                            </PaginationItem>
                          </Pagination>
                          <div style={{ textAlign: "center" }}>
                            <button
                              className="tg-btn rounded-pill my-3 body-2 "
                              style={{
                                background: "#8dbe22",
                                border: "2px solid #8dbe22",
                                color: "white",
                                marginTop: "5px",
                                padding: "5px 10px",
                                fontSize: "16px",
                              }}
                              onClick={handleAgregar}
                            >
                              <FaPlus /> Agregar
                            </button>
                          </div>
                        </div>
                      )
                    )}
                  </Widget>
                </Col>
              </Row>
            </Col>
          </Row>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default DescuentosPage;
