// -- React and related libs
import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  useLocation,
  useNavigate,
  useParams,
  Link,
} from "react-router-dom";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";

import Footer from "../Footer/Footer";

import { v4 as uuidv4 } from "uuid";

import {
  Col,
  Row,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Badge,
} from "reactstrap";

import Widget from "../Widget/Widget.js";

import { useStateWithCallbackLazy } from "use-state-with-callback";

import funnelIcon from "../../images/assets/tables/funnelIcon.svg";

import sTable from "../Tables/Tables.module.scss";
import mock from "../Tables/mock.js";
import { COURT_CHANGE_STATE_RESET } from "../../redux/constants/courtConstants";
import Loader from "../shared/Loader";
import Alert from "../shared/Alerts";
import PaginateComponent from "../shared/paginate";
import { useDispatch, useSelector } from "react-redux";
import {
  listReservas,
  listReservasByCodigo,
  listReservasByEstado,
} from "../../redux/actions/reservasActions";
import { RESERVA_PLACE_RESET } from "../../redux/constants/reservaPlace";
import { RESERVA_ELIMINAR_RESET } from "../../redux/constants/reservasConstants";
import SearchBar from "material-ui-search-bar";
import Search from "../shared/Search";
// -- Component Styles
import s from "./Layout.module.scss";
import Seo from "../shared/seo";
const Layout = () => {
  let location = useLocation();
  const params = useParams();
  //console.log(params);
  const keyword = params.keyword || "";
  const pageNumber = params.pageNumber || 1;
  //console.log(keyword);
  const navigate = useNavigate();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [searchKey, setSearchKey] = useStateWithCallbackLazy("");
  const dispatch = useDispatch();
  const reservasList = useSelector((state) => state.reservas);
  const { loading, error, reservas: reservasAll, page, pages } = reservasList;
  const reservasFilterData = useSelector((state) => state.reservasFiltradas);
  const { reservas } = reservasFilterData;
  const [estado, setEstado] = React.useState("");

  const handleChange = (event) => {
    setEstado(event.target.value);
  };

  const [tableDropdownOpen, setTableMenuOpen] = useState(false);

  const tableMenuOpen = () => {
    setTableMenuOpen(!tableDropdownOpen);
  };

  const handleSearch = (codigo) => {
    setSearchKey(codigo, (current) => {
      dispatch(listReservasByCodigo(current.trim()));
    });
  };

  const handleCambioEstado = (estado) => {
    dispatch(listReservasByEstado(estado));
  };
  //console.log(location);
  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    } else {
      dispatch(listReservas(keyword, pageNumber));
      dispatch({ type: RESERVA_PLACE_RESET });
      dispatch({ type: RESERVA_ELIMINAR_RESET });
      dispatch({ type: COURT_CHANGE_STATE_RESET });
    }
  }, [userInfo, navigate, dispatch, pageNumber, keyword]);

  return (
    <div className={s.root}>
      <Seo
        titlePage="Reservas"
        descriptionPage="Pádel Cuenca. Somos un club conformado por personas amantes del deporte, enfocados en fomentar la vida activa y saludable, priorizando el ejercicio como un hábito que beneficie nuestro estado físico y mental, Disponemos de canchas de Pádel en Cuenca Ecuador para que pueda realizar una reserva."
      />
      <div className={s.wrap}>
        <Header />

        <Sidebar />
        <main className={s.content}>
          <div>
            <Row>
              <Col>
                <Row className="mb-4">
                  <Col>
                    <Widget>
                      <div className={sTable.tableTitle}>
                        <div className="headline-2">Reservas Registradas</div>
                        {reservasAll && <Search />}
                        <Dropdown
                          className="d-none d-sm-block"
                          nav
                          isOpen={tableDropdownOpen}
                          toggle={() => tableMenuOpen()}
                        >
                          <DropdownToggle nav>
                            <img
                              className="d-none d-sm-block"
                              src={funnelIcon}
                              alt="Filtrar..."
                            />
                          </DropdownToggle>
                          <DropdownMenu style={{ marginRight: "80px" }}>
                            <DropdownItem
                              onClick={() => handleCambioEstado("Todas")}
                            >
                              <div>Todas</div>
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => handleCambioEstado("No revisadas")}
                            >
                              <div>No revisadas</div>
                            </DropdownItem>
                            <DropdownItem
                              onClick={() =>
                                handleCambioEstado("Pendientes de Pago")
                              }
                            >
                              <div>Pendientes de Pago</div>
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => handleCambioEstado("Confirmadas")}
                            >
                              <div>Confirmadas</div>
                            </DropdownItem>
                            <DropdownItem
                              onClick={() =>
                                handleCambioEstado("Creado por Admin")
                              }
                            >
                              <div>Creado por Admin</div>
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                      {error ? (
                        <Alert message={error} severity="error" />
                      ) : loading ? (
                        <div style={{ textAlign: "center" }}>
                          <Loader />
                        </div>
                      ) : (
                        reservasAll && (
                          <div className="widget-table-overflow">
                            <Table
                              className="table-striped table-borderless table-hover"
                              responsive
                            >
                              <thead>
                                <tr>
                                  <th className={sTable.nameCol}>Leído</th>
                                  <th className={sTable.nameCol}>CóDIGO</th>
                                  <th>EMAIL</th>
                                  <th>Nombre</th>
                                  <th>número juegos</th>
                                  <th>Precio</th>

                                  <th>ESTADO</th>
                                  <th>método pago</th>
                                  <th>Descripción</th>
                                  <th>EDITAR</th>
                                </tr>
                              </thead>
                              <tbody>
                                {reservasAll.map((item) => (
                                  <tr key={uuidv4()}>
                                    <td>
                                      <div className="checkbox checkbox-primary">
                                        <input
                                          id="checkbox200"
                                          className="styled"
                                          type="checkbox"
                                          disabled
                                          checked={
                                            item.revisado === 0 ? false : true
                                          }
                                        />
                                        <label for="checkbox200" />
                                      </div>
                                    </td>
                                    <td>{item.codigo}</td>
                                    <td>
                                      {item.email.length > 20
                                        ? item.email.slice(0, 20) + "..."
                                        : item.email}
                                    </td>
                                    <td>
                                      {item.usuario === "ADMIN ADMIN"
                                        ? "Admin"
                                        : item.usuario}
                                    </td>
                                    <td>{item.horario.length}</td>
                                    <td>$ {item.total}</td>
                                    <td>
                                      {item.estado === "PENDIENTE DE PAGO" ? (
                                        <Badge color="secondary-yellow">
                                          {item.estado}
                                        </Badge>
                                      ) : item.estado === "CONFIRMADO" ? (
                                        <Badge color="secondary-cyan">
                                          {item.estado}
                                        </Badge>
                                      ) : (
                                        <Badge color="info">
                                          {item.estado}
                                        </Badge>
                                      )}
                                    </td>
                                    <td>
                                      {item.metodo_pago
                                        ? item.metodo_pago
                                        : "--"}
                                    </td>
                                    <td>
                                      {item.descripcion
                                        ? item.descripcion
                                        : "--"}
                                    </td>
                                    <td>
                                      {" "}
                                      <Link
                                        to={`/reserva/editar/${item.codigo}`}
                                      >
                                        {" "}
                                        <i className={"eva eva-edit"} />
                                      </Link>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                            <nav
                              aria-label="Page navigation"
                              class="pagination justify-content-center"
                            >
                              <PaginateComponent
                                pages={pages}
                                page={page}
                                keyword={keyword ? keyword : ""}
                              />
                            </nav>
                          </div>
                        )
                      )}
                    </Widget>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
