import {
  COURT_LIST_REQUEST,
  COURT_LIST_SUCESS,
  COURT_LIST_FAIL,
  COURT_CHANGE_STATE_FAIL,
  COURT_CHANGE_STATE_REQUEST,
  COURT_CHANGE_STATE_RESET,
  COURT_CHANGE_STATE_SUCESS,
} from "../constants/courtConstants";

export const courtListReducer = (state = { courts: [] }, action) => {
  switch (action.type) {
    case COURT_LIST_REQUEST:
      return { loading: true, courts: [] };
    case COURT_LIST_SUCESS:
      return { loading: false, courts: action.payload };
    case COURT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const courtChangeStateReducer = (state = {}, action) => {
  switch (action.type) {
    case COURT_CHANGE_STATE_REQUEST:
      return { loading: true, courts: [] };
    case COURT_CHANGE_STATE_SUCESS:
      return { loading: false, success: action.payload };
    case COURT_CHANGE_STATE_FAIL:
      return { loading: false, error: action.payload };
    case COURT_CHANGE_STATE_RESET:
      return {};
    default:
      return state;
  }
};
