import React, { useEffect, useState } from "react";
import { FaArrowLeft, FaCheck, FaTrashAlt } from "react-icons/fa";
import { v4 as uuidv4 } from "uuid";
import { useNavigate, useParams } from "react-router-dom";

import {
  Col,
  Row,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Button,
  Badge,
} from "reactstrap";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import mock from "../components/Tables/mock";

import Header from "../components/Header/Header";
import Sidebar from "../components/Sidebar/Sidebar";
import Footer from "../components/Footer/Footer";
import Loader from "../components/shared/Loader";
import Message from "../components/shared/Alerts";
import { useDispatch, useSelector } from "react-redux";
import {
  getReservaDetails,
  changeReservaState,
  deleteReserva,
  deleteHoarioReserva,
} from "../redux/actions/reservasActions";
import { RESERVA_ELIMINAR_HORARIO_RESET } from "../redux/constants/reservasConstants";
import s from "../components/Layout/Layout.module.scss";
import tableStyle from "../components/Tables/Tables.module.scss";
import Widget from "../components/Widget/Widget";
import Seo from "../components/shared/seo";
const EditarReservapage = () => {
  const [estado, setEstado] = React.useState("");
  const navigate = useNavigate();
  const handleChange = (event) => {
    setEstado(event.target.value);
  };

  const { id } = useParams();
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const reservaDet = useSelector((state) => state.reservaDetails);
  const { reservaDetails, loading, error, message } = reservaDet;
  const deleteInfo = useSelector((state) => state.eliminarReserva);
  const {
    sucess: successDelete,
    loading: loadingDelete,
    error: errorDelete,
  } = deleteInfo;
  const deleteHorarioInfo = useSelector(
    (state) => state.eliminarHorarioReserva
  );
  const {
    success: successDeleteHorario,
    loading: loadingDeleteHorario,
    error: errorDeleteHorario,
  } = deleteHorarioInfo;

  const [firstTable] = useState(mock.firstTable);
  const [firstTableCurrentPage, setFirstTableCurrentPage] = useState(0);
  const pageSize = 4;
  const firstTablePagesCount = reservaDetails
    ? reservaDetails.horario
      ? Math.ceil(reservaDetails.horario.length / pageSize)
      : Math.ceil(firstTable.length / pageSize)
    : Math.ceil(firstTable.length / pageSize);

  const setFirstTablePage = (e, index) => {
    e.preventDefault();
    setFirstTableCurrentPage(index);
  };

  const deleteHandler = (id) => {
    // e.preventDefault();
    if (window.confirm("Desea eliminar esta reserva ?")) {
      dispatch(deleteReserva(id));
    }
  };
  const deleteHorarioHandler = (codigo, id_horario) => {
    // e.preventDefault();
    if (window.confirm("Desea eliminar este horario de la reserva ?")) {
      if (reservaDetails.horario.length > 1) {
        dispatch(deleteHoarioReserva(codigo, id_horario));
      } else {
        dispatch(deleteReserva(codigo));
      }
    }
  };

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    }
    if (successDelete) {
      navigate("/reservas");
    }
    if (successDeleteHorario) {
      dispatch(getReservaDetails(id));
    }

    dispatch(getReservaDetails(id));
    dispatch({ type: RESERVA_ELIMINAR_HORARIO_RESET });
    //setEstado(reservaDetails ? reservaDetails.estado : "");
  }, [userInfo, navigate, dispatch, id, successDelete, successDeleteHorario]);
  const submitHandler = (e) => {
    e.preventDefault();

    dispatch(changeReservaState(id, estado));
    //dispatch login
    //dispatch(login(email, password));
  };
  return (
    <div className={s.root}>
      <Seo
        titlePage="Reservas"
        descriptionPage="Pádel Cuenca. Somos un club conformado por personas amantes del deporte, enfocados en fomentar la vida activa y saludable, priorizando el ejercicio como un hábito que beneficie nuestro estado físico y mental, Disponemos de canchas de Pádel en Cuenca Ecuador para que pueda realizar una reserva."
      />
      <div className={s.wrap}>
        <Header />

        <Sidebar />
        <main className={s.content}>
          {/* <Breadcrumbs url={location.pathname} /> */}
          {/* <h5>Reservas Realizadas</h5> */}
          <Row>
            <Col>
              {/*  <Row>
                <Col xs={12} xl={6}>
                 
                </Col>
               
              </Row> */}
              <Row className="mb-6">
                <Col xs={12} xl={6}>
                  <Widget>
                    {" "}
                    <div style={{ minHeight: "300px", padding: "20px" }}>
                      {" "}
                      <div className="headline-2">Editar Reserva</div>
                      {error && (
                        <Message
                          message="Algo salió mal. Por favor intentalo más tarde"
                          severity="error"
                        />
                      )}
                      {message && (
                        <Message
                          message="Estado cambiado con éxito"
                          severity="success"
                        />
                      )}
                      {loading && (
                        <div style={{ textAlign: "center" }}>
                          {" "}
                          <Loader />
                        </div>
                      )}
                      {loadingDelete && (
                        <div style={{ textAlign: "center" }}>
                          {" "}
                          <Loader />
                        </div>
                      )}
                      {errorDelete && (
                        <Message
                          message="No se pudo ejecutar la acción. Por favor intentelo más tarde."
                          severity="error"
                        />
                      )}
                      {reservaDetails && (
                        <div style={{ marginTop: "25px" }}>
                          <h6>
                            Código de reserva:{" "}
                            <span style={{ color: "#8dbe22" }}>
                              {reservaDetails.codigo}
                            </span>
                          </h6>
                          <h6>
                            Fecha en la que se realizó la reserva:{" "}
                            <span style={{ color: "#8dbe22" }}>
                              {reservaDetails.fecha_reserva.slice(0, 10)}
                            </span>
                          </h6>
                          <h6>
                            Usuario:{" "}
                            <span style={{ color: "#8dbe22" }}>
                              {reservaDetails.email}
                            </span>
                          </h6>
                          <h6>
                            Nombre:{" "}
                            <span style={{ color: "#8dbe22" }}>
                              {reservaDetails.usuario}
                            </span>
                          </h6>
                          {reservaDetails.telefono && (
                            <h6>
                              Teléfono:{" "}
                              <span style={{ color: "#8dbe22" }}>
                                {reservaDetails.telefono}
                              </span>
                            </h6>
                          )}

                          <h6>
                            Total Pagado:{" "}
                            <span style={{ color: "#8dbe22" }}>
                              $ {reservaDetails.total}
                            </span>
                          </h6>
                          {reservaDetails.metodo_pago ? (
                            <h6>
                              Método de Pago:{" "}
                              <span style={{ color: "#8dbe22" }}>
                                {reservaDetails.metodo_pago}
                              </span>
                            </h6>
                          ) : null}
                          {reservaDetails.descripcion ? (
                            <h6>
                              Descripción:{" "}
                              <span style={{ fontSize: "14px" }}>
                                {reservaDetails.descripcion}
                              </span>
                            </h6>
                          ) : null}

                          <h6>
                            Estado Actual:{" "}
                            <span style={{ color: "#8dbe22" }}>
                              {reservaDetails.estado === "PENDIENTE DE PAGO" ? (
                                <Badge color="secondary-yellow">
                                  {reservaDetails.estado}
                                </Badge>
                              ) : reservaDetails.estado === "CONFIRMADO" ? (
                                <Badge color="secondary-cyan">
                                  {reservaDetails.estado}
                                </Badge>
                              ) : (
                                <Badge color="info">
                                  {reservaDetails.estado}
                                </Badge>
                              )}
                            </span>
                          </h6>
                          {reservaDetails.estado !== "CREADO POR ADMIN" && (
                            <>
                              {" "}
                              <h6 style={{ marginTop: "25px" }}>
                                <p>Cambiar Estado</p>
                                <FormControl component="fieldset">
                                  <RadioGroup
                                    row
                                    aria-label="estado"
                                    name="estado"
                                    value={estado}
                                    onChange={handleChange}
                                    //defaultValue={reservaDetails.estado}
                                  >
                                    <FormControlLabel
                                      value="CONFIRMADO"
                                      control={<Radio />}
                                      label="CONFIRMADO"
                                      labelPlacement="end"
                                    />
                                    <FormControlLabel
                                      value="PENDIENTE"
                                      control={<Radio />}
                                      label="PENDIENTE"
                                      labelPlacement="end"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </h6>
                            </>
                          )}
                          <Button
                            className="rounded-pill my-3 body-2 "
                            color="dark"
                            onClick={() => navigate(-1)}
                          >
                            {/* <Link
                              to="/reservas"
                              color="dark"
                              className="rounded-pill my-3 body-2 "
                              style={{ color: "white" }}
                            > */}
                            <FaArrowLeft /> Regresar
                            {/* </Link> */}
                          </Button>
                          <Button
                            className="rounded-pill my-3 body-2 "
                            color="danger"
                            style={{ color: "white" }}
                            onClick={() => deleteHandler(reservaDetails.codigo)}
                          >
                            <FaTrashAlt /> Eliminar
                          </Button>
                          {reservaDetails.estado !== "CREADO POR ADMIN" && (
                            <Button
                              className="rounded-pill my-3 body-2 "
                              onClick={(e) => submitHandler(e)}
                              color="secondary-red"
                            >
                              <a style={{ color: "white" }}>
                                Confirmar Cambio <FaCheck />
                              </a>
                            </Button>
                          )}
                        </div>
                      )}
                    </div>
                  </Widget>
                </Col>
                <Col xs={12} xl={6}>
                  <Widget>
                    <div className={tableStyle.tableTitle}>
                      <div className="headline-2">Horario Reserva</div>
                    </div>
                    {loadingDeleteHorario && (
                      <div style={{ textAlign: "center" }}>
                        {" "}
                        <Loader />
                      </div>
                    )}
                    {errorDeleteHorario && (
                      <Message
                        message={
                          "No se pudo ejecutar la acción. Por favor intentelo más tarde."
                        }
                        severity="error"
                      />
                    )}
                    {successDeleteHorario && (
                      <Message
                        message="Horario eliminado de la reserva."
                        severity="success"
                      />
                    )}

                    <div className="widget-table-overflow">
                      <Table
                        className={`table-striped table-borderless table-hover ${tableStyle.statesTable}`}
                        responsive
                      >
                        <thead>
                          <tr>
                            <th className="w-25">Cancha</th>
                            <th className="w-25">Fecha</th>
                            <th className="w-25">hora</th>
                            <th className="w-25">Precio Horario</th>
                            <th className="w-25">Eliminar</th>
                          </tr>
                        </thead>
                        <tbody>
                          {reservaDetails &&
                            reservaDetails.horario
                              .slice(
                                firstTableCurrentPage * pageSize,
                                (firstTableCurrentPage + 1) * pageSize
                              )
                              .map((item) => (
                                <tr key={uuidv4()}>
                                  <td className="d-flex align-items-center">
                                    <span className="ml-3">{item.cancha}</span>
                                  </td>
                                  <td>{item.fecha.slice(0, 10)}</td>
                                  <td>{item.hora}</td>
                                  <td>{item.precio}</td>
                                  <td>
                                    <div style={{ textAlign: "center" }}>
                                      <button
                                        style={{
                                          background: "red",
                                          color: "white",
                                          border: "1px solid red",
                                        }}
                                        onClick={() =>
                                          deleteHorarioHandler(
                                            reservaDetails.codigo,
                                            item.id
                                          )
                                        }
                                      >
                                        <FaTrashAlt />
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                        </tbody>
                      </Table>
                      <Pagination
                        className="pagination-borderless"
                        aria-label="Page navigation example"
                      >
                        <PaginationItem disabled={firstTableCurrentPage <= 0}>
                          <PaginationLink
                            onClick={(e) =>
                              setFirstTablePage(e, firstTableCurrentPage - 1)
                            }
                            previous
                            href="#top"
                          />
                        </PaginationItem>
                        {[...Array(firstTablePagesCount)].map((page, i) => (
                          <PaginationItem
                            active={i === firstTableCurrentPage}
                            key={i}
                          >
                            <PaginationLink
                              onClick={(e) => setFirstTablePage(e, i)}
                              href="#top"
                            >
                              {i + 1}
                            </PaginationLink>
                          </PaginationItem>
                        ))}
                        <PaginationItem
                          disabled={
                            firstTableCurrentPage >= firstTablePagesCount - 1
                          }
                        >
                          <PaginationLink
                            onClick={(e) =>
                              setFirstTablePage(e, firstTableCurrentPage + 1)
                            }
                            next
                            href="#top"
                          />
                        </PaginationItem>
                      </Pagination>
                    </div>
                  </Widget>
                </Col>
              </Row>
            </Col>
          </Row>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default EditarReservapage;
