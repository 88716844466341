import {
  HORARIOS_DETAILS_FAIL,
  HORARIOS_DETAILS_REQUEST,
  HORARIOS_DETAILS_RESET,
  HORARIOS_DETAILS_SUCCESS,
  HORARIOS_LIST_CANCHA1_FAIL,
  HORARIOS_LIST_CANCHA1_REQUEST,
  HORARIOS_LIST_CANCHA1_RESET,
  HORARIOS_LIST_CANCHA1_SUCCESS,
  HORARIOS_LIST_CANCHA2_FAIL,
  HORARIOS_LIST_CANCHA2_REQUEST,
  HORARIOS_LIST_CANCHA2_RESET,
  HORARIOS_LIST_CANCHA2_SUCCESS,
  HORARIOS_LIST_PRECIOS_FAIL,
  HORARIOS_LIST_PRECIOS_REQUEST,
  HORARIOS_LIST_PRECIOS_RESET,
  HORARIOS_LIST_PRECIOS_SUCCESS,
  HORARIOS_LIST_DESCUENTOS_FAIL,
  HORARIOS_LIST_DESCUENTOS_REQUEST,
  HORARIOS_LIST_DESCUENTOS_RESET,
  HORARIOS_LIST_DESCUENTOS_SUCCESS,
  DESCUENTO_DETALLE_FAIL,
  DESCUENTO_DETALLE_REQUEST,
  DESCUENTO_DETALLE_RESET,
  DESCUENTO_DETALLE_SUCCESS,
  PRECIO_DETALLE_FAIL,
  PRECIO_DETALLE_REQUEST,
  PRECIO_DETALLE_RESET,
  PRECIO_DETALLE_SUCCESS,
} from "../constants/horariosConstants";

export const horariosPreciosReducer = (state = {}, action) => {
  switch (action.type) {
    case HORARIOS_LIST_PRECIOS_REQUEST:
      return { loading: true };
    case HORARIOS_LIST_PRECIOS_SUCCESS:
      return { loading: false, precios: action.payload };
    case HORARIOS_LIST_PRECIOS_FAIL:
      return { loading: false, error: action.payload };
    case HORARIOS_LIST_PRECIOS_RESET:
      return {};
    default:
      return state;
  }
};

export const detallePrecioReducer = (state = {}, action) => {
  switch (action.type) {
    case PRECIO_DETALLE_REQUEST:
      return { loading: true };
    case PRECIO_DETALLE_SUCCESS:
      return { loading: false, precio: action.payload };
    case PRECIO_DETALLE_FAIL:
      return { loading: false, error: action.payload };
    case PRECIO_DETALLE_RESET:
      return {};
    default:
      return state;
  }
};

export const horariosDescuentosReducer = (state = {}, action) => {
  switch (action.type) {
    case HORARIOS_LIST_DESCUENTOS_REQUEST:
      return { loading: true };
    case HORARIOS_LIST_DESCUENTOS_SUCCESS:
      return { loading: false, descuentos: action.payload };
    case HORARIOS_LIST_DESCUENTOS_FAIL:
      return { loading: false, error: action.payload };
    case HORARIOS_LIST_DESCUENTOS_RESET:
      return {};
    default:
      return state;
  }
};

export const detalleDescuentoReducer = (state = {}, action) => {
  switch (action.type) {
    case DESCUENTO_DETALLE_REQUEST:
      return { loading: true };
    case DESCUENTO_DETALLE_SUCCESS:
      return { loading: false, descuento: action.payload };
    case DESCUENTO_DETALLE_FAIL:
      return { loading: false, error: action.payload };
    case DESCUENTO_DETALLE_RESET:
      return {};
    default:
      return state;
  }
};

export const HorarioListReducer = (state = {}, action) => {
  switch (action.type) {
    case HORARIOS_LIST_CANCHA1_REQUEST:
      return { loading: true };
    case HORARIOS_LIST_CANCHA1_SUCCESS:
      return { loading: false, horario: action.payload };
    case HORARIOS_LIST_CANCHA1_FAIL:
      return { loading: false, error: action.payload };
    case HORARIOS_LIST_CANCHA1_RESET:
      return {};
    default:
      return state;
  }
};
