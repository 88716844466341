import React, { useState, useEffect } from "react";
import api from "../../api/api";
import ApexCharts from "react-apexcharts";

export default function ApexColumnAreaChart({ labels, values, title }) {
  console.log(labels);
  const series = [
    {
      name: title,
      type: "column",
      data: values,
    },
  ];

  const chartSettings = {
    colors: ["#1E88E5", "#C7D0D9"],
    chart: {
      toolbar: {
        show: false,
      },
      height: 350,
      type: "line",
      stacked: false,
    },
    stroke: {
      width: [0, 0],
      curve: "smooth",
    },
    plotOptions: {
      bar: {
        columnWidth: "50%",
      },
    },
    fill: {
      type: "solid",
      opacity: [1, 0.5],
    },
    labels: labels,
    markers: {
      size: 0,
    },
    xaxis: {
      type: "category",
      labels: {
        style: {
          colors: "#6B859E",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: ["#6B859E"],
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return y.toFixed(0) + " veces";
          }
          return y;
        },
      },
    },
  };

  return (
    <ApexCharts
      options={chartSettings}
      series={series}
      type="area"
      height={300}
    />
  );
}
