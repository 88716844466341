// -- React and related libs
import React, { useEffect } from "react";

import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Col, Row } from "reactstrap";
import Header from "../components/Header/Header";
import Sidebar from "../components/Sidebar/Sidebar";
//import Tables from "../components/Tables/Tables";
import Footer from "../components/Footer/Footer";
import Loader from "../components/shared/Loader";
import Message from "../components/shared/Alerts";
import { useDispatch, useSelector } from "react-redux";
import {
  getReservaDetails,
  changeReservaState,
} from "../redux/actions/reservasActions";

// -- Component Styles
import s from "../components/Layout/Layout.module.scss";
import tableStyle from "../components/Tables/Tables.module.scss";
import Widget from "../components/Widget/Widget";

const CalendarPage = () => {
  const [estado, setEstado] = React.useState("");

  const { id } = useParams();
  let location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const reservaDet = useSelector((state) => state.reservaDetails);
  const { reservaDetails, loading, error, message } = reservaDet;

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    }
  }, [userInfo, navigate, dispatch, id]);

  return (
    <div className={s.root}>
      <div className={s.wrap}>
        <Header />

        <Sidebar />
        <main className={s.content}>
          <Row>
            <Col>
              <Row className="mb-6">
                <Col xs={12} xl={12}>
                  <Widget>
                    {" "}
                    <div style={{ minHeight: "300px", padding: "20px" }}>
                      {" "}
                      <div className="headline-2">Calendario de Reservas</div>
                      {error && (
                        <Message
                          message="Algo salió mal. Por favor intentalo más tarde"
                          severity="error"
                        />
                      )}
                      {message && (
                        <Message
                          message="Estado cambiado con éxito"
                          severity="success"
                        />
                      )}
                      {loading && (
                        <div style={{ textAlign: "center" }}>
                          {" "}
                          <Loader />
                        </div>
                      )}
                    </div>
                  </Widget>
                </Col>
              </Row>
            </Col>
          </Row>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default CalendarPage;
