export const HORARIOS_LIST_CANCHA1_REQUEST = "HORARIOS_LIST_CANCHA1_REQUEST";
export const HORARIOS_LIST_CANCHA1_SUCCESS = "HORARIOS_LIST_CANCHA1_SUCCESS";
export const HORARIOS_LIST_CANCHA1_FAIL = "HORARIOS_LIST_CANCHA1_FAIL";
export const HORARIOS_LIST_CANCHA1_RESET = "HORARIOS_LIST_CANCHA1_RESET";

export const HORARIOS_LIST_CANCHA2_REQUEST = "HORARIOS_LIST_CANCHA2_REQUEST";
export const HORARIOS_LIST_CANCHA2_SUCCESS = "HORARIOS_LIST_CANCHA2_SUCCESS";
export const HORARIOS_LIST_CANCHA2_FAIL = "HORARIOS_LIST_CANCHA2_FAIL";
export const HORARIOS_LIST_CANCHA2_RESET = "HORARIOS_LIST_CANCHA2_RESET";

export const HORARIOS_DETAILS_REQUEST = "HORARIOS_DETAILS_REQUEST";
export const HORARIOS_DETAILS_SUCCESS = "HORARIOS_DETAILS_SUCESS";
export const HORARIOS_DETAILS_FAIL = "HORARIOS_DETAILS_FAIL";
export const HORARIOS_DETAILS_RESET = "HORARIOS_DETAILS_RESET";

export const HORARIOS_LIST_PRECIOS_REQUEST = "HORARIOS_LIST_PRECIOS_REQUEST";
export const HORARIOS_LIST_PRECIOS_SUCCESS = "HORARIOS_LIST_PRECIOS_SUCCESS";
export const HORARIOS_LIST_PRECIOS_FAIL = "HORARIOS_LIST_PRECIOS_FAIL";
export const HORARIOS_LIST_PRECIOS_RESET = "HORARIOS_LIST_PRECIOS_RESET";

export const HORARIOS_LIST_DESCUENTOS_REQUEST =
  "HORARIOS_LIST_DESCUENTOS_REQUEST";
export const HORARIOS_LIST_DESCUENTOS_SUCCESS =
  "HORARIOS_LIST_DESCUENTOS_SUCCESS";
export const HORARIOS_LIST_DESCUENTOS_FAIL = "HORARIOS_LIST_DESCUENTOS_FAIL";
export const HORARIOS_LIST_DESCUENTOS_RESET = "HORARIOS_LIST_DESCUENTOS_RESET";

export const PRECIO_DETALLE_REQUEST = "PRECIO_DETALLE_REQUEST";
export const PRECIO_DETALLE_SUCCESS = "PRECIO_DETALLE_SUCCESS";
export const PRECIO_DETALLE_FAIL = "PRECIO_DETALLE_FAIL";
export const PRECIO_DETALLE_RESET = "PRECIO_DETALLE_RESET";

export const DESCUENTO_DETALLE_REQUEST = "PRECIO_DETALLE_REQUEST";
export const DESCUENTO_DETALLE_SUCCESS = "PRECIO_DETALLE_SUCCESS";
export const DESCUENTO_DETALLE_FAIL = "PRECIO_DETALLE_FAIL";
export const DESCUENTO_DETALLE_RESET = "PRECIO_DETALLE_RESET";
