import React, { useEffect, useState } from "react";
import { FaArrowLeft, FaPencilAlt, FaEye } from "react-icons/fa";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import {
  BrowserRouter as Router,
  useLocation,
  useNavigate,
  useParams,
  Link,
} from "react-router-dom";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Col, Row, Button, Form, FormGroup, Table } from "reactstrap";

//import Tables from "../components/Tables/Tables";
import Loader from "../shared/Loader";
import Message from "../shared/Alerts";
import SnackBar from "../shared/Snackbar";
//import Breadcrumbs from "../Breadbrumbs/Breadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import api from "../../api/api";
import Widget from "../Widget/Widget";
import tableStyle from "../Tables/Tables.module.scss";
// -- Component Styles

//import { getPrecioDetails } from "../../redux/actions/horariosActions";

const HabilitarCanchaspage = ({ id }) => {
  const [successData, setSuccessData] = useState(null);
  const [successUpdate, setSuccessUpdate] = useState(null);
  const [error, setError] = useState(null);
  const [errorUpdate, setErrorUpdate] = useState(null);
  const [loading, setLoading] = useState(null);
  const [loadingUpdate, setLoadingUpdate] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [message, setMessage] = useState(null);
  const [selectedSchedule, setSelectedSchedule] = useState([]);

  const handleUptate = (dia, hora, estadoAnterior) => {
    const estado = estadoAnterior === "1" ? 2 : 1;
    console.log(estado);
    if (window.confirm("Está seguro ?")) {
      const edit = async () => {
        setLoadingUpdate(true);
        setErrorUpdate(null);
        setSuccessUpdate(null);
        try {
          const { data } = await api.post("/horario-cancha/habilitar", [
            { cancha: id, dia, hora, estado },
          ]);
          setSuccessUpdate(true);
          setLoadingUpdate(false);
        } catch (error) {
          console.log(error);
          setErrorUpdate("Algo salió mal.");
          setLoadingUpdate(false);
        }
      };

      edit();
    }
  };

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    }
    setLoading(true);
    setError(null);
    setSuccessData(null);
    const load = async () => {
      try {
        const { data } = await api.post("/horario-cancha/disponible", {
          id_cancha: id,
        });
        setSuccessData(data);
        setLoading(false);
      } catch (error) {
        //console.log(error);
        setError("Algo salió mal.");
        setLoading(false);
      }
    };
    if (successUpdate) {
      load();
    }

    load();
  }, [userInfo, navigate, dispatch, id, successUpdate]);

  return (
    <div>
      {message && (
        <SnackBar
          severity="error"
          message="Por favor actualize uno de los valores"
        />
      )}

      {errorUpdate && (
        <Message
          message="Algo salió mal. Por favor intentalo más tarde"
          severity="error"
        />
      )}
      {loadingUpdate && (
        <div style={{ textAlign: "center" }}>
          {" "}
          <Loader />
        </div>
      )}
      {successUpdate && (
        <div style={{ textAlign: "center", marginTop: "25px" }}>
          <Message
            message="Horario actualizado correctamente"
            severity="success"
          />
        </div>
      )}
      {error ? (
        <Message
          message="Algo salió mal. Por favor intentalo más tarde"
          severity="error"
        />
      ) : loading ? (
        <Loader />
      ) : (
        successData && (
          <Row className="mb-12">
            <Col xs={12} xl={8}>
              <Widget>
                <Table
                  className={`table-striped table-borderless ${tableStyle.statesTable}`}
                  responsive
                >
                  <thead>
                    <tr>
                      <th>Hora</th>
                      <th>Lunes</th>
                      <th>Martes</th>
                      <th>Miércoles</th>
                      <th>Jueves</th>
                      <th>Viernes</th>
                    </tr>
                  </thead>
                  <tbody>
                    {successData.horario_semanal.map((hor, index) => (
                      <tr key={index}>
                        <th scope="row" style={{ fontWeight: "700" }}>
                          {hor.hora}
                        </th>
                        {hor.horario.map((item, i) => (
                          <td key={i}>
                            {" "}
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onChange={(e) => {
                                    e.preventDefault();
                                    handleUptate(
                                      item.dia,
                                      hor.hora,
                                      item.estado
                                    );
                                  }}
                                  checked={item.estado === "1" ? true : false}
                                  color="success"
                                  sx={{
                                    "& .MuiSvgIcon-root": {
                                      fontSize: 22,
                                    },
                                  }}
                                />
                              }
                              label={
                                <div
                                  style={{
                                    fontSize: "14px",
                                  }}
                                >
                                  {item.estado === "1" ? (
                                    <p
                                      style={{
                                        fontWeight: "700",
                                        color: "green",
                                      }}
                                    >
                                      Habilitado
                                    </p>
                                  ) : (
                                    <p
                                      style={{
                                        fontWeight: "700",
                                        color: "red",
                                      }}
                                    >
                                      Deshabilitado
                                    </p>
                                  )}
                                </div>
                              }
                            />
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Widget>
            </Col>
            <Col xs={12} xl={4}>
              <Widget>
                <Table
                  className={`table-striped table-borderless ${tableStyle.statesTable}`}
                  responsive
                >
                  <thead>
                    <tr>
                      <th>Hora</th>
                      <th>Sábado</th>
                      <th>Domingo</th>
                    </tr>
                  </thead>
                  <tbody>
                    {successData.horario_fin_semana.map((hor, index) => (
                      <tr key={index}>
                        <th scope="row" style={{ fontWeight: "700" }}>
                          {hor.hora}
                        </th>
                        {hor.horario.map((item, i) => (
                          <td key={i}>
                            {" "}
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onChange={(e) => {
                                    e.preventDefault();
                                    handleUptate(
                                      item.dia,
                                      hor.hora,
                                      item.estado
                                    );
                                  }}
                                  checked={item.estado === "1" ? true : false}
                                  color="success"
                                  sx={{
                                    "& .MuiSvgIcon-root": {
                                      fontSize: 22,
                                    },
                                  }}
                                />
                              }
                              label={
                                <div
                                  style={{
                                    fontSize: "14px",
                                  }}
                                >
                                  {item.estado === "1" ? (
                                    <p
                                      style={{
                                        fontWeight: "700",
                                        color: "green",
                                      }}
                                    >
                                      Habilitado
                                    </p>
                                  ) : (
                                    <p
                                      style={{
                                        fontWeight: "700",
                                        color: "red",
                                      }}
                                    >
                                      Deshabilitado
                                    </p>
                                  )}
                                </div>
                              }
                            />
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Widget>
            </Col>
          </Row>
        )
      )}
    </div>
  );
};

export default HabilitarCanchaspage;
