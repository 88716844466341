import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

const EventWrapper = ({ event, children }) => {
  const { title, className } = children.props;
  const customClass = `${className} rbc-event--${event.type}`;
  const hourStart = moment(event.start).hour();
  const hourStop = moment(event.end).hour();
  const gridRowStart = hourStart + 1;
  const type = event.type;

  return (
    <Link
      title={title}
      to={`/reserva/editar/${event.codigo}`}
      /* style={{ color: "white" }} */
      className={customClass}
      style={{
        gridRow: `${gridRowStart} / span ${hourStop - hourStart}`,
        minHeight: "60px",
        fontSize: type === 4 ? "10px" : "12px",
      }}
    >
      {" "}
      {children.props.children}
    </Link>
  );
};

export default EventWrapper;
