// -- React and related libs
import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Redirect,
  useLocation,
  useNavigate,
  Link,
} from "react-router-dom";
import {
  Col,
  Row,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  ButtonDropdown,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Label,
  Badge,
} from "reactstrap";

import Loader from "../../components/shared/Loader";

import Header from "../../components/Header/Header";
//import HomePage from "../../pages/HomePage";
import Sidebar from "../../components/Sidebar/Sidebar";
import Tables from "../../components/Tables/Tables";
import Footer from "../../components/Footer/Footer";
//import Breadcrumbs from "../Breadbrumbs/Breadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import Widget from "../../components/Widget/Widget.js";
// -- Component Styles
import { listCourts } from "../../redux/actions/courtActions";
import {
  addCourtToReserva,
  addUserToReserva,
  deleteScheduleToReserva,
} from "../../redux/actions/reservasActions";
import s from "../../components/Layout/Layout.module.scss";
import cancha from "../../images/canchaPadel1.jpg";
import cancha2 from "../../images/canchapadel2.jpg";
import "../../styles/canchasCard.css";
import Seo from "../../components/shared/seo";
const CanchasPage = () => {
  const dispatch = useDispatch();
  let location = useLocation();
  const navigate = useNavigate();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const courts = useSelector((state) => state.courtList);
  const { loading, error, courts: courtsList } = courts;
  //console.log(location);
  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    }
    dispatch(addUserToReserva(userInfo.email));
    dispatch(deleteScheduleToReserva);
    dispatch(listCourts());
  }, [userInfo, navigate, dispatch]);

  const addCourtHandler = (id, nombre, direccion, precio, estado) => {
    dispatch(addCourtToReserva(id, nombre, direccion, precio, estado));
    navigate("/reservas/horario");
  };

  return (
    <div className={s.root}>
      <Seo
        titlePage="Reservas"
        descriptionPage="Pádel Cuenca. Somos un club conformado por personas amantes del deporte, enfocados en fomentar la vida activa y saludable, priorizando el ejercicio como un hábito que beneficie nuestro estado físico y mental, Disponemos de canchas de Pádel en Cuenca Ecuador para que pueda realizar una reserva."
      />
      <div className={s.wrap}>
        <Header />
        <Sidebar />
        <main className={s.content}>
          <Row>
            <Col>
              <Row>
                <Col>
                  <Widget>
                    {" "}
                    <div
                      className={s.tableTitle}
                      style={{ marginLeft: "15px", marginTop: "15px" }}
                    >
                      <div className="headline-2">Realizar una reserva</div>
                    </div>
                    <div style={{ marginLeft: "15px", marginTop: "15px" }}>
                      1/2 Seleccionar una cancha
                    </div>
                    <div className="content-wrapper">
                      {loading ? (
                        <Loader />
                      ) : error ? (
                        <p>error</p>
                      ) : (
                        courtsList.map((cou, index) => (
                          <div className="news-card" key={index}>
                            {cou.estado_cancha.id === 1 ? (
                              <a
                                onClick={() => {
                                  addCourtHandler(
                                    cou.id,
                                    cou.nombre,
                                    cou.direccion,
                                    cou.precio,
                                    cou.estado_cancha.id_estado_cancha
                                  );
                                }}
                                className="news-card__card-link"
                              ></a>
                            ) : null}

                            <img
                              src={cou.id === 1 ? cancha : cancha2}
                              alt=""
                              className="news-card__image"
                            />
                            <div className="news-card__text-wrapper">
                              <h2 className="news-card__title">{cou.nombre}</h2>
                              {cou.estado_cancha.id === 1 ? (
                                <div
                                  className="news-card__post-date"
                                  style={{ color: "green", fontWeight: "900" }}
                                >
                                  Disponible
                                </div>
                              ) : (
                                <div
                                  className="news-card__post-date"
                                  style={{ color: "red", fontWeight: "900" }}
                                >
                                  No Disponible
                                </div>
                              )}
                              <div className="news-card__post-date"></div>
                              <div className="news-card__details-wrapper">
                                <p className="news-card__excerpt">
                                  {cou.descripcion}, ubicada en {cou.direccion}.
                                  Horario de {cou.hora_apertura} a{" "}
                                  {cou.hora_cierre}
                                </p>
                                <p
                                  style={{
                                    fontWeight: "900",
                                    fontSize: "16px",
                                  }}
                                >
                                  Precio: Varía según el horario
                                </p>
                                {cou.estado_cancha.id === 1 ? (
                                  <button
                                    href="#"
                                    className="news-card__read-more"
                                    onClick={() => {
                                      addCourtHandler(
                                        cou.id,
                                        cou.nombre,
                                        cou.direccion,
                                        cou.precio,
                                        cou.estado_cancha.id_estado_cancha
                                      );
                                    }}
                                  >
                                    Reservar
                                    <i className="fas fa-long-arrow-alt-right"></i>
                                  </button>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                  </Widget>
                </Col>
              </Row>
            </Col>
          </Row>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default CanchasPage;
