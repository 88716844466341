import React from "react";
import moment from "moment";
import "moment/locale/es";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import EventWrapper from "./EventWrapper";

const CalendarComponent2 = ({ data }) => {
  //console.log(data);
  const localizer = momentLocalizer(moment);

  return (
    <div style={{ minWidth: "80vw" }}>
      <Calendar
        components={{
          eventWrapper: EventWrapper,
          // event: Event
        }}
        localizer={localizer}
        minTime="07:00:00"
        events={data}
        startAccessor="start"
        selectable
        endAccessor="end"
        defaultView="week"
        //view="week"
        views={["week", "day", "agenda"]}
      />
    </div>
  );
};

export default CalendarComponent2;
