// -- React and related libs
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Header from "../../components/Header/Header";
//import HomePage from "../../pages/HomePage";
import Sidebar from "../../components/Sidebar/Sidebar";
import Footer from "../../components/Footer/Footer";
//import Breadcrumbs from "../Breadbrumbs/Breadcrumbs";
import { useSelector } from "react-redux";

// -- Component Styles
import s from "../../components/Layout/Layout.module.scss";
import iconDescuento from "../../images/iconDescuento.png";
import "../../styles/horariosCard.css";
import Seo from "../../components/shared/seo";
const HorariosSelectOptionPage = () => {
  const navigate = useNavigate();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    }
  }, [userInfo, navigate]);

  const handlePrecio = (e) => {
    e.preventDefault();
    navigate("/horarios/precios");
  };
  const handleDescuentos = (e) => {
    e.preventDefault();
    navigate("/horarios/descuentos");
  };

  const handleHabilitar = (e) => {
    e.preventDefault();
    navigate("/precios/administrar");
  };

  return (
    <div className={s.root}>
      <Seo
        titlePage="Horarios"
        descriptionPage="Pádel Cuenca. Somos un club conformado por personas amantes del deporte, enfocados en fomentar la vida activa y saludable, priorizando el ejercicio como un hábito que beneficie nuestro estado físico y mental, Disponemos de canchas de Pádel en Cuenca Ecuador para que pueda realizar una reserva."
      />
      <div className={s.wrap}>
        <Header />
        <Sidebar />
        <main className={s.content}>
          <div
            className="headline-2"
            style={{
              textAlign: "center",
              margin: "10px 0px",
              fontSize: "26px",
            }}
          >
            Seleccione una Opción
          </div>
          <div class="row1-container">
            <div
              class="box box-down cyan"
              onClick={(e) => {
                handlePrecio(e);
              }}
            >
              <h2 style={{ fontSize: "18px" }}>Precios</h2>
              <p>
                {" "}
                Agregue o modifique los precios de los horarios registrados.{" "}
              </p>
              <img
                src="https://assets.codepen.io/2301174/icon-supervisor.svg"
                alt=""
              />
            </div>

            <div
              class="box box-down red"
              onClick={(e) => {
                handleDescuentos(e);
              }}
            >
              <h2 style={{ fontSize: "18px" }}>Descuentos</h2>
              <p>
                Agregue o modifique los descuentos en los horarios que se
                ofrecen a los socios.
              </p>
              <img src={iconDescuento} alt="" />
            </div>

            <div
              class="box box-down blue"
              onClick={(e) => {
                handleHabilitar(e);
              }}
            >
              <h2 style={{ fontSize: "16px" }}>Administrar</h2>
              <p>
                Cambiar precios y descuentos en todos los horarios de las
                canchas.
              </p>
              <img
                src="https://assets.codepen.io/2301174/icon-calculator.svg"
                alt=""
              />
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default HorariosSelectOptionPage;
