export const RESERVA_LIST_REQUEST = "RESERVA_LIST_REQUEST";
export const RESERVA_LIST_SUCCESS = "RESERVA_LIST_SUCCESS";
export const RESERVA_LIST_FAIL = "RESERVA_LIST_FAIL";

export const RESERVA_DETAILS_REQUEST = "RESERVA_DETAILS_REQUEST";
export const RESERVA_DETAILS_SUCCESS = "RESERVA_DETAILS_SUCESS";
export const RESERVA_DETAILS_FAIL = "RESERVA_DETAILS_FAIL";
export const RESERVA_DETAILS_RESET = "RESERVA_DETAILS_RESET";

export const RESERVA_CHANGE_STATE_REQUEST = "RESERVA_CHANGE_STATE_REQUEST";
export const RESERVA_CHANGE_STATE_SUCCESS = "RESERVA_CHANGE_STATE_SUCCESS";
export const RESERVA_CHANGE_STATE_FAIL = "RESERVA_CHANGE_STATE_FAIL";

export const RESERVA_ADD_COURT = "RESERVA_ADD_COURT";
export const RESERVA_DELETE_COURT = "RESERVA_DELETE_COURT";

export const RESERVA_ADD_USER = "RESERVA_ADD_USER";
export const RESERVA_DELETE_USER = "RESERVA_DELETE_USER";

export const RESERVA_ADD_SCHEDULE = "RESERVA_ADD_SCHEDULE";
export const RESERVA_DELETE_SCHEDULE = "RESERVA_DELETE_SCHEDULE";

export const RESERVA_PLACE_REQUEST = "RESERVA_PLACE_REQUEST";
export const RESERVA_PLACE_SUCCESS = "RESERVA_DELETE_SUCESS";
export const RESERVA_PLACE_FAIL = "RESERVA_DELETE_FAIL";

export const RESERVA_NO_REVISADAS_REQUEST = "RESERVA_NO_REVISADAS_REQUEST";
export const RESERVA_NO_REVISADAS_SUCCESS = "RESERVA_NO_REVISADAS_SUCCESS";
export const RESERVA_NO_REVISADAS_FAIL = "RESERVA_NO_REVISADAS_FAIL";

export const RESERVA_NO_REVISADAS_NUMBER_REQUEST =
  "RESERVA_NO_REVISADAS_NUMBER_REQUEST";
export const RESERVA_NO_REVISADAS_NUMBER_SUCCESS =
  "RESERVA_NO_REVISADAS_NUMBER_SUCCESS";
export const RESERVA_NO_REVISADAS_NUMBER_FAIL =
  "RESERVA_NO_REVISADAS_NUMBER_FAIL";

export const RESERVA_FILTRADA_GET = "RESERVA_FILTRADA_GET";
export const RESERVA_FILTRADA_DETELE = "RESERVA_FILTRADA_DETELE";

export const RESERVA_ELIMINAR_REQUEST = "RESERVA_ELIMINAR_REQUEST";
export const RESERVA_ELIMINAR_SUCCESS = "RESERVA_ELIMINAR_SUCCESS";
export const RESERVA_ELIMINAR_FAIL = "RESERVA_ELIMINAR_FAIL";
export const RESERVA_ELIMINAR_RESET = "RESERVA_ELIMINAR_RESET";

export const RESERVA_ELIMINAR_HORARIO_REQUEST =
  "RESERVA_ELIMINAR_HORARIO_REQUEST";
export const RESERVA_ELIMINAR_HORARIO_SUCCESS =
  "RESERVA_ELIMINAR_HORARIO_SUCCESS";
export const RESERVA_ELIMINAR_HORARIO_FAIL = "RESERVA_ELIMINAR_HORARIO_FAIL";
export const RESERVA_ELIMINAR_HORARIO_RESET = "RESERVA_ELIMINAR_HORARIO_RESET";
