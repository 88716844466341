import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Widget from "../components/Widget/Widget";
import Footer from "../components/Footer/Footer";
import logo from "../images/logoPadelAdmin.svg";

import loginImage from "../images/loginImage.svg";
//import SofiaLogo from "../images/Icons/SofiaLogo.js";
import GoogleIcon from "../images/Icons/AuthIcons/GoogleIcon.js";
import TwitterIcon from "../images/Icons/AuthIcons/TwitterIcon.js";
import FacebookIcon from "../images/Icons/AuthIcons/FacebookIcon.js";
import GithubIcon from "../images/Icons/AuthIcons/GithubIcon.js";
import LinkedinIcon from "../images/Icons/AuthIcons/LinkedinIcon.js";

import {
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  FormText,
  Input,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { login, logout } from "../redux/actions/userActions";
import Alert from "../components/shared/Alerts";
import Loader from "../components/shared/Loader";
import Seo from "../components/shared/seo";
const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [message, setMessage] = useState(false);
  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    if (userInfo) {
      if (userInfo.admin) {
        navigate("/panel");
      } else {
        dispatch(logout());
        setMessage(!message);
      }
    }
  }, [navigate, userInfo, message, dispatch]);
  const submitHandler = (e) => {
    e.preventDefault();
    //dispatch login
    dispatch(login(email, password));
  };

  return (
    <div className="auth-page">
      <Seo
        titlePage="Ingresar"
        descriptionPage="Pádel Cuenca. Somos un club conformado por personas amantes del deporte, enfocados en fomentar la vida activa y saludable, priorizando el ejercicio como un hábito que beneficie nuestro estado físico y mental, Disponemos de canchas de Pádel en Cuenca Ecuador para que pueda realizar una reserva."
      />
      <Container className="col-12">
        <Row className="d-flex align-items-center">
          <Col xs={12} lg={6} className="left-column">
            <Widget className="widget-auth widget-p-lg">
              <div className="d-flex align-items-center justify-content-between py-3">
                <p className="auth-header mb-0">Login</p>
                <div className="logo-block">
                  <img src={logo} />
                  <p className="mb-0 titlePadel">Pádel Cuenca</p>
                </div>
              </div>
              <div className="auth-info my-2">
                <p>
                  Bienvenid@ al panel administrativo de
                  <b> Pádel Cuenca</b> por favor ingrese sus credenciales para
                  iniciar sesión.
                </p>
              </div>
              {error && <Alert message={error} severity="error" />}
              {message === true && (
                <Alert
                  message="Ud no tiene permisos de administrador"
                  severity="error"
                />
              )}
              {loading && (
                <div style={{ textAlign: "center" }}>
                  <Loader />
                </div>
              )}
              <form onSubmit={submitHandler}>
                <FormGroup className="my-3">
                  <FormText>Email</FormText>
                  <Input
                    id="email"
                    className="input-transparent pl-3"
                    value={email}
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                    type="email"
                    required
                    name="email"
                    placeholder="Email"
                  />
                </FormGroup>
                <FormGroup className="my-3">
                  <div className="d-flex justify-content-between">
                    <FormText>Password</FormText>
                    {/*  <Link to="/error">Forgot password?</Link> */}
                  </div>
                  <Input
                    id="password"
                    className="input-transparent pl-3"
                    value={password}
                    onChange={(event) => {
                      setPassword(event.target.value);
                    }}
                    type="password"
                    required
                    name="password"
                    placeholder="Password"
                  />
                </FormGroup>
                <div className="bg-widget d-flex justify-content-center">
                  <Button
                    className="rounded-pill my-3"
                    type="submit"
                    color="secondary-red"
                  >
                    Ingresar
                  </Button>
                </div>
                <p className="dividing-line my-3">&#8195;-&#8195;</p>
                {/* <div className="d-flex align-items-center my-3">
                  <p className="social-label mb-0">Login with</p>
                  <div className="socials">
                    <a href="https://flatlogic.com/">
                      <GoogleIcon />
                    </a>
                    <a href="https://flatlogic.com/">
                      <TwitterIcon />
                    </a>
                    <a href="https://flatlogic.com/">
                      <FacebookIcon />
                    </a>
                    <a href="https://flatlogic.com/">
                      <GithubIcon />
                    </a>
                    <a href="https://flatlogic.com/">
                      <LinkedinIcon />
                    </a>
                  </div>
                </div> */}
                {/*  <Link to="/register">Don’t have an account? Sign Up here</Link> */}
              </form>
            </Widget>
          </Col>
          <Col xs={0} lg={6} className="right-column">
            <div>
              {" "}
              <img src={loginImage} alt="Error page" />
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default LoginPage;
