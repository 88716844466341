import api from "../../api/api";
import {
  NOTICIAS_LIST_FAIL,
  NOTICIAS_LIST_REQUEST,
  NOTICIAS_LIST_RESET,
  NOTICIAS_DELETE_FAIL,
  NOTICIAS_DELETE_REQUEST,
  NOTICIAS_DELETE_RESET,
  NOTICIAS_DELETE_SUCCESS,
  NOTICIAS_DETALLE_FAIL,
  NOTICIAS_DETALLE_REQUEST,
  NOTICIAS_DETALLE_SUCCESS,
  NOTICIAS_LIST_SUCCESS,
} from "../constants/noticiasConstants";

export const listNoticias = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: NOTICIAS_LIST_REQUEST,
    });

    const { data } = await api.get(`/noticia` /* config */);

    dispatch({
      type: NOTICIAS_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({
      type: NOTICIAS_LIST_FAIL,
      payload: message,
    });
  }
};

export const getNoticiaDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: NOTICIAS_DETALLE_REQUEST,
    });

    const { data } = await api.get(`/noticia/${id}` /* config */);

    dispatch({
      type: NOTICIAS_DETALLE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({
      type: NOTICIAS_DETALLE_FAIL,
      payload: message,
    });
  }
};
