import api from "../../api/api";
import {
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_DETAILS_RESET,
  USER_LIST_FAIL,
  USER_LIST_RESET,
  USER_LIST_SUCCESS,
  USER_LIST_REQUEST,
  USER_LIST_FILTRADA_DETELE,
  USER_LIST_FILTRADA_GET,
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
} from "../constants/userConstants";

export const login = (email, password) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await api.post(
      "/usuario/auth",
      { email, password },
      config
    );

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    //set user to localStorage
    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const logout = () => (dispatch) => {
  localStorage.removeItem("userInfo");
  /*  localStorage.removeItem('cartItems')
    localStorage.removeItem('shippingAddress')
    localStorage.removeItem('paymentMethod') */
  dispatch({ type: USER_LOGOUT });
  /* dispatch({ type: USER_DETAILS_RESET }); */
  /*  dispatch({ type: ORDER_LIST_MY_RESET })
    dispatch({ type: USER_LIST_RESET }) */
  document.location.href = "/login";
};

export const listUsers = () => async (dispatch) => {
  try {
    dispatch({
      type: USER_LIST_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await api.get(
      "/usuario",

      config
    );

    dispatch({
      type: USER_LIST_SUCCESS,
      payload: data,
    });
    dispatch({
      type: USER_LIST_FILTRADA_GET,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listUsersByCodigo = (filter) => async (dispatch, getState) => {
  var usersFilter;
  if (filter === "") {
    usersFilter = getState().users.users;
  } else {
    usersFilter = getState().users.users.filter(function (element) {
      return (
        element.nombre.toLowerCase().includes(filter.toLowerCase()) |
        element.apellido.toLowerCase().includes(filter.toLowerCase()) |
        element.telefono.includes(filter) |
        element.email.includes(filter)
      );
    });
  }

  dispatch({
    type: USER_LIST_FILTRADA_GET,
    payload: usersFilter,
  });
};

export const getUserDetails = (id) => async (dispatch) => {
  try {
    dispatch({
      type: USER_DETAILS_REQUEST,
    });
    const { data } = await api.get(`/usuario/${id}`);
    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
