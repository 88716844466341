import api from "../../api/api";
import {
  HORARIOS_DETAILS_FAIL,
  HORARIOS_DETAILS_REQUEST,
  HORARIOS_DETAILS_RESET,
  HORARIOS_DETAILS_SUCCESS,
  HORARIOS_LIST_CANCHA1_FAIL,
  HORARIOS_LIST_CANCHA1_REQUEST,
  HORARIOS_LIST_CANCHA1_RESET,
  HORARIOS_LIST_CANCHA1_SUCCESS,
  HORARIOS_LIST_CANCHA2_FAIL,
  HORARIOS_LIST_CANCHA2_REQUEST,
  HORARIOS_LIST_CANCHA2_RESET,
  HORARIOS_LIST_CANCHA2_SUCCESS,
  HORARIOS_LIST_PRECIOS_FAIL,
  HORARIOS_LIST_PRECIOS_REQUEST,
  HORARIOS_LIST_PRECIOS_RESET,
  HORARIOS_LIST_PRECIOS_SUCCESS,
  HORARIOS_LIST_DESCUENTOS_FAIL,
  HORARIOS_LIST_DESCUENTOS_REQUEST,
  HORARIOS_LIST_DESCUENTOS_RESET,
  HORARIOS_LIST_DESCUENTOS_SUCCESS,
  PRECIO_DETALLE_FAIL,
  PRECIO_DETALLE_REQUEST,
  PRECIO_DETALLE_RESET,
  PRECIO_DETALLE_SUCCESS,
  DESCUENTO_DETALLE_FAIL,
  DESCUENTO_DETALLE_REQUEST,
  DESCUENTO_DETALLE_SUCCESS,
} from "../constants/horariosConstants";

export const listHorariosCancha = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: HORARIOS_LIST_CANCHA1_REQUEST,
    });

    const { data } = await api.post(`/horario-cancha/disponible`, {
      id_cancha: id,
    });

    dispatch({
      type: HORARIOS_LIST_CANCHA1_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    /*  if (message === "Not authorized, token failed") {
      dispatch(logout());
    } */
    dispatch({
      type: HORARIOS_LIST_CANCHA1_FAIL,
      payload: message,
    });
  }
};

export const listPrecios = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: HORARIOS_LIST_PRECIOS_REQUEST,
    });

    const { data } = await api.get(`/precio`);

    dispatch({
      type: HORARIOS_LIST_PRECIOS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    /*  if (message === "Not authorized, token failed") {
        dispatch(logout());
      } */
    dispatch({
      type: HORARIOS_LIST_PRECIOS_FAIL,
      payload: message,
    });
  }
};

export const listDescuentos = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: HORARIOS_LIST_DESCUENTOS_REQUEST,
    });

    const { data } = await api.get(`/descuento`);

    dispatch({
      type: HORARIOS_LIST_DESCUENTOS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    /*  if (message === "Not authorized, token failed") {
        dispatch(logout());
      } */
    dispatch({
      type: HORARIOS_LIST_DESCUENTOS_FAIL,
      payload: message,
    });
  }
};

export const getPrecioDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRECIO_DETALLE_REQUEST,
    });

    const { data } = await api.get(`/precio/${id}` /* config */);

    dispatch({
      type: PRECIO_DETALLE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({
      type: PRECIO_DETALLE_FAIL,
      payload: message,
    });
  }
};

export const getDescuentoDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DESCUENTO_DETALLE_REQUEST,
    });

    const { data } = await api.get(`/descuento/${id}` /* config */);

    dispatch({
      type: DESCUENTO_DETALLE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({
      type: DESCUENTO_DETALLE_FAIL,
      payload: message,
    });
  }
};
