import api from "../../api/api";

import {
  RECARGA_LIST_FAIL,
  RECARGA_LIST_REQUEST,
  RECARGA_LIST_SUCCESS,
  RECARGA_DETAILS_FAIL,
  RECARGA_DETAILS_REQUEST,
  RECARGA_DETAILS_RESET,
  RECARGA_DETAILS_SUCCESS,
  RECARGA_CHANGE_STATE_FAIL,
  RECARGA_CHANGE_STATE_REQUEST,
  RECARGA_CHANGE_STATE_SUCCESS,
  RECARGA_NO_REVISADAS_FAIL,
  RECARGA_NO_REVISADAS_NUMBER_FAIL,
  RECARGA_NO_REVISADAS_NUMBER_REQUEST,
  RECARGA_NO_REVISADAS_NUMBER_SUCCESS,
  RECARGA_NO_REVISADAS_REQUEST,
  RECARGA_NO_REVISADAS_SUCCESS,
  RECARGA_FILTRADA_DETELE,
  RECARGA_FILTRADA_GET,
} from "../constants/recargasConstants";
import { logout } from "./userActions";

export const listRecargas = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: RECARGA_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    /*  const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      } */

    const { data } = await api.get(`/recarga/all` /* config */);

    dispatch({
      type: RECARGA_LIST_SUCCESS,
      payload: data,
    });
    dispatch({
      type: RECARGA_FILTRADA_GET,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: RECARGA_LIST_FAIL,
      payload: message,
    });
  }
};

export const listRecargasByCodigo = (filter) => async (dispatch, getState) => {
  //const reservas = getState().reservas.reservas;
  //console.log(reservas);
  var recargasFilter;
  if (filter === "") {
    recargasFilter = getState().recargas.recargas;
  } else {
    recargasFilter = getState().recargas.recargas.filter(function (element) {
      return (
        element.client_transaction_id.includes(filter) |
        element.usuario.email.includes(filter) |
        element.usuario.apellido.includes(filter) |
        element.usuario.username.includes(filter)
      );
    });
  }

  dispatch({
    type: RECARGA_FILTRADA_GET,
    payload: recargasFilter,
  });
};

export const listRecargasByEstado = (filter) => async (dispatch, getState) => {
  //const reservas = getState().reservas.reservas;
  //console.log(filter);
  //console.log(reservas);
  var recargasFilter;
  if (filter === "Todas") {
    recargasFilter = getState().recargas.recargas;
  } else if (filter === "No revisadas") {
    recargasFilter = getState().recargas.recargas.filter(function (element) {
      return element.revisado === 0;
    });
  } else if (filter === "Pendientes de Pago") {
    recargasFilter = getState().recargas.recargas.filter(function (element) {
      return element.estado_recarga.estado_reserva === "PENDIENTE DE PAGO";
    });
  } else if (filter === "Confirmadas") {
    recargasFilter = getState().recargas.recargas.filter(function (element) {
      return element.estado_recarga.estado_reserva === "CONFIRMADO";
    });
  } else if (filter === "Creado por Admin") {
    recargasFilter = getState().recargas.recargas.filter(function (element) {
      return element.estado_recarga.estado_reserva === "CREADO POR ADMIN";
    });
  } else {
    recargasFilter = getState().recargas.recargas;
  }

  dispatch({
    type: RECARGA_FILTRADA_GET,
    payload: recargasFilter,
  });
};

export const listRecargasNoRevisadas = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: RECARGA_NO_REVISADAS_REQUEST,
    });

    const { data } = await api.get(`/recarga/revisado` /* config */);

    dispatch({
      type: RECARGA_NO_REVISADAS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: RECARGA_NO_REVISADAS_FAIL,
      payload: message,
    });
  }
};

export const getNumeroRecargasNoRevisadas =
  () => async (dispatch, getState) => {
    try {
      dispatch({
        type: RECARGA_NO_REVISADAS_NUMBER_REQUEST,
      });

      const { data } = await api.get(`/RECARGA/numero` /* config */);

      dispatch({
        type: RECARGA_NO_REVISADAS_NUMBER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Not authorized, token failed") {
        dispatch(logout());
      }
      dispatch({
        type: RECARGA_NO_REVISADAS_NUMBER_FAIL,
        payload: message,
      });
    }
  };

export const getRecargaDetails = (codigo) => async (dispatch, getState) => {
  try {
    dispatch({
      type: RECARGA_DETAILS_REQUEST,
    });

    const { data } = await api.get(`/recarga/codigo/${codigo}` /* config */);

    dispatch({
      type: RECARGA_DETAILS_SUCCESS,
      payload: data,
    });

    if (data.revisado === 0) {
      await api.post(`/recarga/revisado`, {
        codigo: data.client_transaction_id,
      });
    }
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    /* if (message === 'Not authorized, token failed') {
        dispatch(logout())
      } */
    dispatch({
      type: RECARGA_DETAILS_FAIL,
      payload: message,
    });
  }
};

export const changeRecargaState =
  (id, estado) => async (dispatch, getState) => {
    const estado_recarga = estado === "CONFIRMADO" ? 1 : 2;
    //console.log(estado_RECARGA);
    try {
      dispatch({
        type: RECARGA_CHANGE_STATE_REQUEST,
      });

      /*  const {
      userLogin: { userInfo },
    } = getState(); */

      /*  const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }  */

      const { data } = await api.post(
        `/recarga/estado`,
        { id, estado_recarga } /* , config */
      );

      dispatch({
        type: RECARGA_CHANGE_STATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      /* if (message === 'Not authorized, token failed') {
      dispatch(logout())
    } */
      dispatch({
        type: RECARGA_CHANGE_STATE_FAIL,
        payload: message,
      });
    }
  };
