import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import { runtime } from "./reducers/navigationReducers";
import {
  userLoginReducer,
  userListReducer,
  userListFilterReducer,
  userDetailsReducer,
} from "./reducers/userReducers";
import {
  reservasListReducer,
  reservaDetailsReducer,
  reservaReducer,
  numberReservasNoRevisadosListReducer,
  reservasNoRevisadosListReducer,
  reservasListFilterReducer,
  reservaEliminarReducer,
  reservaEliminarHorarioReducer,
} from "./reducers/reservasReducers";
import {
  recargaDetailsReducer,
  recargasListReducer,
  numberRecargasNoRevisadosListReducer,
  recargasNoRevisadosListReducer,
  recargasListFilterReducer,
} from "./reducers/recargasReducers";
import {
  courtListReducer,
  courtChangeStateReducer,
} from "./reducers/courtReducers";
import { scheduleListReducer } from "./reducers/scheduleReducers";
import { reservaPlaceReducer } from "./reducers/reservaPlaceReducers";

import {
  horariosPreciosReducer,
  horariosDescuentosReducer,
  detalleDescuentoReducer,
  detallePrecioReducer,
  HorarioListReducer,
} from "./reducers/horariosReducers";

import {
  noticiaDetailsReducer,
  noticiasListReducer,
} from "./reducers/noticiasReducers";
const reducer = combineReducers({
  courtList: courtListReducer,
  courtChangeState: courtChangeStateReducer,
  reservaAdmin: reservaReducer,
  navigation: runtime,
  userLogin: userLoginReducer,
  schedule: scheduleListReducer,
  reservas: reservasListReducer,
  reservasFiltradas: reservasListFilterReducer,
  reservasNorevisadas: reservasNoRevisadosListReducer,
  eliminarReserva: reservaEliminarReducer,
  eliminarHorarioReserva: reservaEliminarHorarioReducer,
  numeroReservasNorevisadas: numberReservasNoRevisadosListReducer,
  recargasNorevisadas: recargasNoRevisadosListReducer,
  numeroRecargasNorevisadas: numberRecargasNoRevisadosListReducer,
  placeReserva: reservaPlaceReducer,
  reservaDetails: reservaDetailsReducer,
  recargas: recargasListReducer,
  users: userListReducer,
  userDetails: userDetailsReducer,
  usersFiltradas: userListFilterReducer,
  recargasFiltradas: recargasListFilterReducer,
  recargaDetails: recargaDetailsReducer,
  horariosPrecios: horariosPreciosReducer,
  precioDetalle: detallePrecioReducer,
  descuentoDetalle: detalleDescuentoReducer,
  horariosDescuentos: horariosDescuentosReducer,
  listHorario: HorarioListReducer,
  noticiasList: noticiasListReducer,
  noticiaDetalle: noticiaDetailsReducer,
});

const middleware = [thunk];

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const initialState = {
  navigation: {
    sidebarOpened: false,
    activeItem: JSON.parse(localStorage.getItem("staticSidebar"))
      ? window.location.pathname
      : null,
  },
  userLogin: {
    userInfo: userInfoFromStorage,
  },
};

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
