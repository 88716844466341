import React from "react";
import { Pagination } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const Paginate = ({ pages, page, keyword = "" }) => {
  return (
    pages > 1 && (
      <Pagination>
        <LinkContainer
          to={
            keyword
              ? `/reservas/search/${keyword}/page/${1}`
              : `/reservas/page/${1}`
          }
        >
          <Pagination.Item>1</Pagination.Item>
        </LinkContainer>
        <LinkContainer
          to={
            keyword
              ? `/reservas/search/${keyword}/page/${page - 1}`
              : `/reservas/page/${page - 1}`
          }
        >
          <Pagination.Item disabled={page === 1}>{"<"}</Pagination.Item>
        </LinkContainer>
        {page !== 1 && page !== pages && (
          <LinkContainer
            to={
              keyword
                ? `/reservas/search/${keyword}/page/${page}`
                : `/reservas/page/${page}`
            }
          >
            <Pagination.Item>{page}</Pagination.Item>
          </LinkContainer>
        )}
        {page + 4 < pages && (
          <LinkContainer
            to={
              keyword
                ? `/reservas/search/${keyword}/page/${page + 1}`
                : `/reservas/page/${page + 1}`
            }
          >
            <Pagination.Item>{page + 1}</Pagination.Item>
          </LinkContainer>
        )}

        {page + 4 < pages && (
          <LinkContainer
            to={
              keyword
                ? `/reservas/search/${keyword}/page/${page + 2}`
                : `/reservas/page/${page + 2}`
            }
          >
            <Pagination.Item>{page + 2}</Pagination.Item>
          </LinkContainer>
        )}
        {page + 4 < pages && (
          <LinkContainer
            to={
              keyword
                ? `/reservas/search/${keyword}/page/${page + 3}`
                : `/reservas/page/${page + 3}`
            }
          >
            <Pagination.Item>{page + 3}</Pagination.Item>
          </LinkContainer>
        )}
        {page + 4 < pages && (
          <LinkContainer
            to={
              keyword
                ? `/reservas/search/${keyword}/page/${page + 4}`
                : `/reservas/page/${page + 4}`
            }
          >
            <Pagination.Item>{page + 4}</Pagination.Item>
          </LinkContainer>
        )}

        {/*  <Pagination.Item style={{ fontSize: "12px" }}>.....</Pagination.Item> */}
        {page + 1 > pages && page < pages ? (
          <LinkContainer
            to={
              keyword
                ? `/reservas/search/${keyword}/page/${page + 1}`
                : `/reservas/page/${page + 1}`
            }
          >
            <Pagination.Item>{page + 1}</Pagination.Item>
          </LinkContainer>
        ) : null}
        {/* {page + 2 > pages && page < pages ? (
          <LinkContainer
            to={
              keyword
                ? `/reservas/search/${keyword}/page/${page + 2}`
                : `/reservas/page/${page + 2}`
            }
          >
            <Pagination.Item>{page + 2}</Pagination.Item>
          </LinkContainer>
        ) : null} */}
        {pages === page && (
          <LinkContainer
            to={
              keyword
                ? `/reservas/search/${keyword}/page/${page - 2}`
                : `/reservas/page/${page - 2}`
            }
          >
            <Pagination.Item>{page - 2}</Pagination.Item>
          </LinkContainer>
        )}
        {pages === page && (
          <LinkContainer
            to={
              keyword
                ? `/reservas/search/${keyword}/page/${page - 1}`
                : `/reservas/page/${page - 1}`
            }
          >
            <Pagination.Item>{page - 1}</Pagination.Item>
          </LinkContainer>
        )}

        <LinkContainer
          to={
            keyword
              ? `/reservas/search/${keyword}/page/${page + 1}`
              : `/reservas/page/${page + 1}`
          }
        >
          <Pagination.Item disabled={page === pages}>{">"}</Pagination.Item>
        </LinkContainer>
        <LinkContainer
          to={
            keyword
              ? `/reservas/search/${keyword}/page/${pages}`
              : `/reservas/page/${pages}`
          }
        >
          <Pagination.Item>{pages}</Pagination.Item>
        </LinkContainer>
        {/*  {[...Array(pages).keys()].map((x) => (
          <LinkContainer
            key={x + 1}
            to={
              keyword
                ? `/reservas/search/${keyword}/page/${x + 1}`
                : `/reservas/page/${x + 1}`
            }
          >
            <Pagination.Item active={x + 1 === page}>{x + 1}</Pagination.Item>
          </LinkContainer>
        ))} */}
      </Pagination>
    )
  );
};

export default Paginate;
