import {
  NOTICIAS_LIST_FAIL,
  NOTICIAS_LIST_REQUEST,
  NOTICIAS_LIST_RESET,
  NOTICIAS_DELETE_FAIL,
  NOTICIAS_DELETE_REQUEST,
  NOTICIAS_DELETE_RESET,
  NOTICIAS_DELETE_SUCCESS,
  NOTICIAS_DETALLE_FAIL,
  NOTICIAS_DETALLE_REQUEST,
  NOTICIAS_DETALLE_RESET,
  NOTICIAS_DETALLE_SUCCESS,
  NOTICIAS_LIST_SUCCESS,
  NOTICIAS_UPDATE_FAIL,
  NOTICIAS_UPDATE_REQUEST,
  NOTICIAS_UPDATE_RESET,
  NOTICIAS_UPDATE_SUCCESS,
} from "../constants/noticiasConstants";

export const noticiasListReducer = (state = {}, action) => {
  switch (action.type) {
    case NOTICIAS_LIST_REQUEST:
      return { loading: true };
    case NOTICIAS_LIST_SUCCESS:
      return { loading: false, noticias: action.payload };
    case NOTICIAS_LIST_FAIL:
      return { loading: false, error: action.payload };
    case NOTICIAS_LIST_RESET:
      return {};
    default:
      return state;
  }
};

export const noticiaDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case NOTICIAS_DETALLE_REQUEST:
      return { loading: true };
    case NOTICIAS_DETALLE_SUCCESS:
      return { loading: false, noticia: action.payload };
    case NOTICIAS_DETALLE_FAIL:
      return { loading: false, error: action.payload };
    case NOTICIAS_DETALLE_RESET:
      return {};
    default:
      return state;
  }
};
