import {
  RESERVA_LIST_FAIL,
  RESERVA_LIST_REQUEST,
  RESERVA_LIST_SUCCESS,
  RESERVA_DETAILS_FAIL,
  RESERVA_DETAILS_REQUEST,
  RESERVA_DETAILS_RESET,
  RESERVA_DETAILS_SUCCESS,
  RESERVA_CHANGE_STATE_FAIL,
  RESERVA_CHANGE_STATE_REQUEST,
  RESERVA_CHANGE_STATE_SUCCESS,
  RESERVA_ADD_COURT,
  RESERVA_ADD_SCHEDULE,
  RESERVA_ADD_USER,
  RESERVA_DELETE_COURT,
  RESERVA_DELETE_SCHEDULE,
  RESERVA_DELETE_USER,
  RESERVA_NO_REVISADAS_FAIL,
  RESERVA_NO_REVISADAS_NUMBER_FAIL,
  RESERVA_NO_REVISADAS_NUMBER_REQUEST,
  RESERVA_NO_REVISADAS_NUMBER_SUCCESS,
  RESERVA_NO_REVISADAS_REQUEST,
  RESERVA_NO_REVISADAS_SUCCESS,
  RESERVA_FILTRADA_DETELE,
  RESERVA_FILTRADA_GET,
  RESERVA_ELIMINAR_FAIL,
  RESERVA_ELIMINAR_REQUEST,
  RESERVA_ELIMINAR_RESET,
  RESERVA_ELIMINAR_SUCCESS,
  RESERVA_ELIMINAR_HORARIO_FAIL,
  RESERVA_ELIMINAR_HORARIO_REQUEST,
  RESERVA_ELIMINAR_HORARIO_SUCCESS,
  RESERVA_ELIMINAR_HORARIO_RESET,
} from "../constants/reservasConstants";

export const reservaReducer = (
  state = { court: {}, user: {}, schedule: [], reservaResult: {} },
  action
) => {
  switch (action.type) {
    case RESERVA_ADD_COURT:
      return {
        loading: true,
        ...state,
        court: action.payload,
      };
    case RESERVA_DELETE_COURT:
      return {
        loading: true,
        ...state,
        court: {},
      };
    case RESERVA_ADD_USER:
      return {
        loading: true,
        ...state,
        user: action.payload,
      };
    case RESERVA_DELETE_USER:
      return {
        loading: true,
        ...state,
        user: {},
      };
    case RESERVA_ADD_SCHEDULE:
      return {
        loading: true,
        ...state,
        schedule: action.payload,
      };
    case RESERVA_DELETE_SCHEDULE:
      return {
        loading: true,
        ...state,
        schedule: [],
      };

    default:
      return state;
  }
};

export const reservasListReducer = (state = {}, action) => {
  switch (action.type) {
    case RESERVA_LIST_REQUEST:
      return {
        loading: true,
      };
    case RESERVA_LIST_SUCCESS:
      return {
        loading: false,
        reservas: action.payload.reservas,
        pages: action.payload.totalPages,
        page: action.payload.currentPage,
      };
    case RESERVA_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const reservasListFilterReducer = (state = {}, action) => {
  switch (action.type) {
    case RESERVA_FILTRADA_GET:
      return {
        reservas: action.payload,
      };
    case RESERVA_FILTRADA_DETELE:
      return {};

    default:
      return state;
  }
};

export const reservasNoRevisadosListReducer = (state = {}, action) => {
  switch (action.type) {
    case RESERVA_NO_REVISADAS_REQUEST:
      return {
        loading: true,
      };
    case RESERVA_NO_REVISADAS_SUCCESS:
      return {
        loading: false,
        reservas: action.payload,
      };
    case RESERVA_NO_REVISADAS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const numberReservasNoRevisadosListReducer = (state = {}, action) => {
  switch (action.type) {
    case RESERVA_NO_REVISADAS_NUMBER_REQUEST:
      return {
        loading: true,
      };
    case RESERVA_NO_REVISADAS_NUMBER_SUCCESS:
      return {
        loading: false,
        numeroReservas: action.payload,
      };
    case RESERVA_NO_REVISADAS_NUMBER_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const reservaDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case RESERVA_DETAILS_REQUEST:
      return {
        loading: true,
      };
    case RESERVA_DETAILS_SUCCESS:
      return {
        loading: false,
        reservaDetails: action.payload,
      };
    case RESERVA_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case RESERVA_DETAILS_RESET:
      return {};
    case RESERVA_CHANGE_STATE_REQUEST:
      return {
        loading: true,
      };
    case RESERVA_CHANGE_STATE_SUCCESS:
      return {
        loading: false,
        reservaDetails: action.payload,
        message: true,
      };
    case RESERVA_CHANGE_STATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const reservaEliminarReducer = (state = {}, action) => {
  switch (action.type) {
    case RESERVA_ELIMINAR_REQUEST:
      return {
        loading: true,
      };
    case RESERVA_ELIMINAR_SUCCESS:
      return {
        loading: false,
        sucess: action.payload,
      };
    case RESERVA_ELIMINAR_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case RESERVA_ELIMINAR_RESET:
      return {};

    default:
      return state;
  }
};

export const reservaEliminarHorarioReducer = (state = {}, action) => {
  switch (action.type) {
    case RESERVA_ELIMINAR_HORARIO_REQUEST:
      return {
        loading: true,
      };
    case RESERVA_ELIMINAR_HORARIO_SUCCESS:
      return {
        loading: false,
        success: action.payload,
      };
    case RESERVA_ELIMINAR_HORARIO_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case RESERVA_ELIMINAR_HORARIO_RESET:
      return {};

    default:
      return state;
  }
};
