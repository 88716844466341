import {
  RECARGA_LIST_FAIL,
  RECARGA_LIST_REQUEST,
  RECARGA_LIST_SUCCESS,
  RECARGA_DETAILS_FAIL,
  RECARGA_DETAILS_REQUEST,
  RECARGA_DETAILS_RESET,
  RECARGA_DETAILS_SUCCESS,
  RECARGA_CHANGE_STATE_FAIL,
  RECARGA_CHANGE_STATE_REQUEST,
  RECARGA_CHANGE_STATE_SUCCESS,
  RECARGA_NO_REVISADAS_FAIL,
  RECARGA_NO_REVISADAS_NUMBER_FAIL,
  RECARGA_NO_REVISADAS_NUMBER_REQUEST,
  RECARGA_NO_REVISADAS_NUMBER_SUCCESS,
  RECARGA_NO_REVISADAS_REQUEST,
  RECARGA_NO_REVISADAS_SUCCESS,
  RECARGA_FILTRADA_GET,
  RECARGA_FILTRADA_DETELE,
} from "../constants/recargasConstants";

export const recargasListReducer = (state = {}, action) => {
  switch (action.type) {
    case RECARGA_LIST_REQUEST:
      return {
        loading: true,
      };
    case RECARGA_LIST_SUCCESS:
      return {
        loading: false,
        recargas: action.payload,
      };
    case RECARGA_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const recargasListFilterReducer = (state = {}, action) => {
  switch (action.type) {
    case RECARGA_FILTRADA_GET:
      return {
        recargas: action.payload,
      };
    case RECARGA_FILTRADA_DETELE:
      return {};

    default:
      return state;
  }
};

export const recargasNoRevisadosListReducer = (state = {}, action) => {
  switch (action.type) {
    case RECARGA_NO_REVISADAS_REQUEST:
      return {
        loading: true,
      };
    case RECARGA_NO_REVISADAS_SUCCESS:
      return {
        loading: false,
        RECARGAs: action.payload,
      };
    case RECARGA_NO_REVISADAS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const numberRecargasNoRevisadosListReducer = (state = {}, action) => {
  switch (action.type) {
    case RECARGA_NO_REVISADAS_NUMBER_REQUEST:
      return {
        loading: true,
      };
    case RECARGA_NO_REVISADAS_NUMBER_SUCCESS:
      return {
        loading: false,
        numeroRECARGAs: action.payload,
      };
    case RECARGA_NO_REVISADAS_NUMBER_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const recargaDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case RECARGA_DETAILS_REQUEST:
      return {
        loading: true,
      };
    case RECARGA_DETAILS_SUCCESS:
      return {
        loading: false,
        recargaDetails: action.payload,
      };
    case RECARGA_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case RECARGA_DETAILS_RESET:
      return {};
    case RECARGA_CHANGE_STATE_REQUEST:
      return {
        loading: true,
      };
    case RECARGA_CHANGE_STATE_SUCCESS:
      return {
        loading: false,
        recargaDetails: action.payload,
        message: true,
      };
    case RECARGA_CHANGE_STATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
