import React, { useEffect, useState } from "react";
import { FaArrowLeft, FaPencilAlt, FaEye } from "react-icons/fa";
import {
  BrowserRouter as Router,
  useLocation,
  useNavigate,
  useParams,
  Link,
} from "react-router-dom";

import { Col, Row, Button, Form, FormGroup } from "reactstrap";

import Header from "../../components/Header/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
//import Tables from "../components/Tables/Tables";
import Footer from "../../components/Footer/Footer";
import Loader from "../../components/shared/Loader";
import Message from "../../components/shared/Alerts";
import SnackBar from "../../components/shared/Snackbar";
//import Breadcrumbs from "../Breadbrumbs/Breadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import api from "../../api/api";

// -- Component Styles
import s from "../../components/Layout/Layout.module.scss";
import { getPrecioDetails } from "../../redux/actions/horariosActions";
import Widget from "../../components/Widget/Widget";
import TextField from "@mui/material/TextField";
import Seo from "../../components/shared/seo";
import { useForm } from "react-hook-form";

const PrecioEditpage = () => {
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);
  const { id } = useParams();
  const {
    register,
    handleSubmit,

    setValue,

    formState: { errors },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    criteriaMode: "firstError",
    shouldFocusError: true,
  });
  const onSubmit = (data) => {
    if (window.confirm("Desea modificar este precio ?")) {
      setLoading(true);
      setError(null);
      setSuccess(null);
      const edit = async () => {
        try {
          const { res } = await api.post("/precio/actualizar", {
            id,
            nombre: data.nombre,
            precio: data.precio,
          });
          setSuccess(true);
          setLoading(false);
        } catch (error) {
          //console.log(error);
          setError("Algo salió mal.");
          setLoading(false);
        }
      };

      edit();
    }
  };

  const navigate = useNavigate();

  let location = useLocation();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const detalleInfo = useSelector((state) => state.precioDetalle);
  const { loading: loadingDetalle, error: errorDetalle, precio } = detalleInfo;
  const [message, setMessage] = useState(null);

  const handleRegresar = (e) => {
    e.preventDefault();
    navigate(-1);
  };
  const noticiasHandler = (e) => {
    e.preventDefault();
    navigate("/horarios/precios");
  };

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    }
    const getDefault = () => {
      if (precio) {
      } else {
        dispatch(getPrecioDetails(id));
      }
    };
    getDefault();
  }, [userInfo, navigate, dispatch, id, precio]);

  return (
    <div className={s.root}>
      <Seo
        titlePage="Horarios"
        descriptionPage="Pádel Cuenca. Somos un club conformado por personas amantes del deporte, enfocados en fomentar la vida activa y saludable, priorizando el ejercicio como un hábito que beneficie nuestro estado físico y mental, Disponemos de canchas de Pádel en Cuenca Ecuador para que pueda realizar una reserva."
      />
      <div className={s.wrap}>
        <Header />

        <Sidebar />
        <main className={s.content}>
          {message && (
            <SnackBar
              severity="error"
              message="Por favor actualize uno de los valores"
            />
          )}
          <Row>
            <Col>
              <button
                className="tg-btn rounded-pill my-3 body-2 "
                style={{
                  background: "#41404B",
                  color: "white",
                  marginTop: "5px",
                  padding: "5px 10px",
                  fontSize: "16px",
                }}
                onClick={handleRegresar}
              >
                <FaArrowLeft /> Regresar
              </button>
              <Row className="mb-12">
                <Col xs={12} xl={12}>
                  <Widget>
                    {" "}
                    <div style={{ minHeight: "300px", padding: "20px" }}>
                      {" "}
                      <div className="headline-2">Editar Precio</div>
                      {error && (
                        <Message
                          message="Algo salió mal. Por favor intentalo más tarde"
                          severity="error"
                        />
                      )}
                      {loading && (
                        <div style={{ textAlign: "center" }}>
                          {" "}
                          <Loader />
                        </div>
                      )}
                      {success && (
                        <div style={{ textAlign: "center", marginTop: "25px" }}>
                          <Message
                            message="Precio Actualizado con éxito"
                            severity="success"
                          />
                          {/*  <div>
                            <Button
                              className="rounded-pill my-3 body-2 "
                              color="primary"
                              style={{ color: "white" }}
                              type="submit"
                              onClick={(e) => noticiasHandler(e)}
                            >
                              <FaEye /> Ver todos los precios
                            </Button>
                          </div> */}
                        </div>
                      )}
                      {errorDetalle ? (
                        <Message
                          message="Algo salió mal. Por favor intentalo más tarde"
                          severity="error"
                        />
                      ) : loadingDetalle ? (
                        <Loader />
                      ) : (
                        precio && (
                          <div style={{ marginTop: "25px", maxWidth: "700px" }}>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                              <TextField
                                id="outlined-textarea"
                                label="Nombre"
                                multiline
                                style={{
                                  minWidth: "330px",
                                }}
                                rows={1}
                                {...register("nombre")}
                                defaultValue={precio.nombre}
                              />

                              <FormGroup>
                                <TextField
                                  {...register("precio", {})}
                                  id="outlined-textarea"
                                  type="text"
                                  label="Precio"
                                  multiline
                                  style={{
                                    minWidth: "330px",
                                    marginTop: "25px",
                                  }}
                                  rows={1}
                                  defaultValue={precio.precio}
                                />
                              </FormGroup>

                              <Button
                                className="rounded-pill my-3 body-2 "
                                color="warning"
                                style={{ color: "white" }}
                                type="submit"
                              >
                                <FaPencilAlt /> Editar
                              </Button>
                            </Form>
                          </div>
                        )
                      )}
                    </div>
                  </Widget>
                </Col>
              </Row>
            </Col>
          </Row>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default PrecioEditpage;
