import React, { useEffect } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Seo from "../../components/shared/seo";
import Header from "../../components/Header/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import Footer from "../../components/Footer/Footer";

import { useDispatch, useSelector } from "react-redux";

import s from "../../components/Layout/Layout.module.scss";
import HorarioHabilitarComponent from "../../components/Horario/HorarioHabilitarComponent";

const HabilitarCanchaspage = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const handleRegresar = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    }
  }, [userInfo, navigate, dispatch]);

  return (
    <div className={s.root}>
      <Seo
        titlePage="Horarios"
        descriptionPage="Pádel Cuenca. Somos un club conformado por personas amantes del deporte, enfocados en fomentar la vida activa y saludable, priorizando el ejercicio como un hábito que beneficie nuestro estado físico y mental, Disponemos de canchas de Pádel en Cuenca Ecuador para que pueda realizar una reserva."
      />
      <div className={s.wrap}>
        <Header />

        <Sidebar />
        <main className={s.content}>
          <div style={{ minHeight: "300px", padding: "10px" }}>
            {" "}
            <div>
              <div
                className="headline-2"
                style={{
                  textAlign: "center",
                  margin: "10px 0px",
                  fontSize: "26px",
                }}
              >
                Horarios
              </div>
            </div>
            <div>
              <div
                className="headline-2"
                style={{ color: "#8dbe22", textAlign: "center" }}
              >
                Cancha 1
              </div>
            </div>
            <div style={{ marginTop: "25px" }}>
              <HorarioHabilitarComponent id={1} />
            </div>
            <div
              className="headline-2"
              style={{
                textAlign: "center",
                margin: "10px 0px",
                fontSize: "26px",
              }}
            >
              Horarios
            </div>
            <div
              className="headline-2"
              style={{ color: "#8dbe22", textAlign: "center" }}
            >
              Cancha 2
            </div>
            <div style={{ marginTop: "25px" }}>
              <HorarioHabilitarComponent id={2} />
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default HabilitarCanchaspage;
