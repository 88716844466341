export const RECARGA_LIST_REQUEST = "RECARGA_LIST_REQUEST";
export const RECARGA_LIST_SUCCESS = "RECARGA_LIST_SUCCESS";
export const RECARGA_LIST_FAIL = "RECARGA_LIST_FAIL";

export const RECARGA_DETAILS_REQUEST = "RECARGA_DETAILS_REQUEST";
export const RECARGA_DETAILS_SUCCESS = "RECARGA_DETAILS_SUCESS";
export const RECARGA_DETAILS_FAIL = "RECARGA_DETAILS_FAIL";
export const RECARGA_DETAILS_RESET = "RECARGA_DETAILS_RESET";

export const RECARGA_CHANGE_STATE_REQUEST = "RECARGA_CHANGE_STATE_REQUEST";
export const RECARGA_CHANGE_STATE_SUCCESS = "RECARGA_CHANGE_STATE_SUCCESS";
export const RECARGA_CHANGE_STATE_FAIL = "RECARGA_CHANGE_STATE_FAIL";

export const RECARGA_NO_REVISADAS_REQUEST = "RECARGA_NO_REVISADAS_REQUEST";
export const RECARGA_NO_REVISADAS_SUCCESS = "RECARGA_NO_REVISADAS_SUCCESS";
export const RECARGA_NO_REVISADAS_FAIL = "RECARGA_NO_REVISADAS_FAIL";

export const RECARGA_NO_REVISADAS_NUMBER_REQUEST =
  "RECARGA_NO_REVISADAS_NUMBER_REQUEST";
export const RECARGA_NO_REVISADAS_NUMBER_SUCCESS =
  "RECARGA_NO_REVISADAS_NUMBER_SUCCESS";
export const RECARGA_NO_REVISADAS_NUMBER_FAIL =
  "RECARGA_NO_REVISADAS_NUMBER_FAIL";

export const RECARGA_FILTRADA_GET = "RECARGA_FILTRADA_GET";
export const RECARGA_FILTRADA_DETELE = "RECARGA_FILTRADA_DETELE";
