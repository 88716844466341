import api from "../../api/api";

import {
  RESERVA_LIST_FAIL,
  RESERVA_LIST_REQUEST,
  RESERVA_LIST_SUCCESS,
  RESERVA_DETAILS_FAIL,
  RESERVA_DETAILS_REQUEST,
  RESERVA_DETAILS_RESET,
  RESERVA_DETAILS_SUCCESS,
  RESERVA_CHANGE_STATE_FAIL,
  RESERVA_CHANGE_STATE_REQUEST,
  RESERVA_CHANGE_STATE_SUCCESS,
  RESERVA_ADD_COURT,
  RESERVA_ADD_SCHEDULE,
  RESERVA_ADD_USER,
  RESERVA_DELETE_COURT,
  RESERVA_DELETE_SCHEDULE,
  RESERVA_DELETE_USER,
  RESERVA_NO_REVISADAS_FAIL,
  RESERVA_NO_REVISADAS_NUMBER_FAIL,
  RESERVA_NO_REVISADAS_NUMBER_REQUEST,
  RESERVA_NO_REVISADAS_NUMBER_SUCCESS,
  RESERVA_NO_REVISADAS_REQUEST,
  RESERVA_NO_REVISADAS_SUCCESS,
  RESERVA_FILTRADA_DETELE,
  RESERVA_FILTRADA_GET,
  RESERVA_ELIMINAR_FAIL,
  RESERVA_ELIMINAR_REQUEST,
  RESERVA_ELIMINAR_RESET,
  RESERVA_ELIMINAR_SUCCESS,
  RESERVA_ELIMINAR_HORARIO_FAIL,
  RESERVA_ELIMINAR_HORARIO_REQUEST,
  RESERVA_ELIMINAR_HORARIO_RESET,
  RESERVA_ELIMINAR_HORARIO_SUCCESS,
} from "../constants/reservasConstants";
import { logout } from "./userActions";

export const addCourtToReserva =
  (id, nombre_cancha, direccion, precio, estado_cancha) => async (dispatch) => {
    dispatch({
      type: RESERVA_ADD_COURT,
      payload: {
        cancha: id,
        nombre_cancha: nombre_cancha,
        direccion: direccion,
        precio: precio,
        estado_cancha: estado_cancha,
      },
    });
  };

export const addUserToReserva = (email) => async (dispatch) => {
  dispatch({
    type: RESERVA_ADD_USER,
    payload: {
      email: email,
    },
  });
};

export const addScheduleToReserva = (shedule) => async (dispatch) => {
  dispatch({
    type: RESERVA_ADD_SCHEDULE,
    payload: shedule,
  });
};

export const deleteUserToReserva = () => async (dispatch) => {
  dispatch({
    type: RESERVA_DELETE_USER,
  });
};

export const deleteScheduleToReserva = () => async (dispatch) => {
  //console.log("fdfrgrgrrgdddrrrqqqq");
  dispatch({
    type: RESERVA_DELETE_SCHEDULE,
  });
};

export const deleteCourtToReserva = () => async (dispatch) => {
  dispatch({
    type: RESERVA_DELETE_COURT,
  });
};

export const listReservas =
  (keyword = "", pageNumber = "") =>
  async (dispatch, getState) => {
    console.log(pageNumber);
    try {
      dispatch({
        type: RESERVA_LIST_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();
      const { data } = await api.get(
        `/reserva/allpage?page=${pageNumber}&filtro=${keyword}` /* config */
      );

      dispatch({
        type: RESERVA_LIST_SUCCESS,
        payload: data,
      });
      dispatch({
        type: RESERVA_FILTRADA_GET,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Not authorized, token failed") {
        dispatch(logout());
      }
      dispatch({
        type: RESERVA_LIST_FAIL,
        payload: message,
      });
    }
  };
export const listReservasByCodigo = (filter) => async (dispatch, getState) => {
  //const reservas = getState().reservas.reservas;
  //console.log(reservas);
  var reservasFilter;
  if (filter === "") {
    reservasFilter = getState().reservas.reservas;
  } else {
    reservasFilter = getState().reservas.reservas.filter(function (element) {
      return (
        element.codigo.toLowerCase().includes(filter.toLowerCase()) |
        element.usuario.toLowerCase().includes(filter.toLowerCase()) |
        element.email.toLowerCase().includes(filter.toLowerCase()) |
        /* element.descripcion &&*/
        element.descripcion.toLowerCase().includes(filter.toLowerCase())
      );
    });
  }

  dispatch({
    type: RESERVA_FILTRADA_GET,
    payload: reservasFilter,
  });
};

export const listReservasByEstado = (filter) => async (dispatch, getState) => {
  //const reservas = getState().reservas.reservas;
  console.log(filter);
  //console.log(reservas);
  var reservasFilter;
  if (filter === "Todas") {
    reservasFilter = getState().reservas.reservas;
  } else if (filter === "No revisadas") {
    reservasFilter = getState().reservas.reservas.filter(function (element) {
      return element.revisado === 0;
    });
  } else if (filter === "Pendientes de Pago") {
    reservasFilter = getState().reservas.reservas.filter(function (element) {
      return element.estado === "PENDIENTE DE PAGO";
    });
  } else if (filter === "Confirmadas") {
    reservasFilter = getState().reservas.reservas.filter(function (element) {
      return element.estado === "CONFIRMADO";
    });
  } else if (filter === "Creado por Admin") {
    reservasFilter = getState().reservas.reservas.filter(function (element) {
      return element.estado === "CREADO POR ADMIN";
    });
  } else {
    reservasFilter = getState().reservas.reservas;
  }

  dispatch({
    type: RESERVA_FILTRADA_GET,
    payload: reservasFilter,
  });
};

export const listReservasNoRevisadas = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: RESERVA_NO_REVISADAS_REQUEST,
    });

    const { data } = await api.get(`/reserva/revisado` /* config */);
    const responseRecarga = await api.get(`/recarga/revisado` /* config */);
    dispatch({
      type: RESERVA_NO_REVISADAS_SUCCESS,
      payload: {
        data,
        numero: data.length,
        recargas: responseRecarga.data,
        numeroRecargas: responseRecarga.data.length,
      },
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: RESERVA_NO_REVISADAS_FAIL,
      payload: message,
    });
  }
};

export const getNumeroReservasNoRevisadas =
  () => async (dispatch, getState) => {
    try {
      dispatch({
        type: RESERVA_NO_REVISADAS_NUMBER_REQUEST,
      });

      const { data } = await api.get(`/reserva/numero` /* config */);

      dispatch({
        type: RESERVA_NO_REVISADAS_NUMBER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Not authorized, token failed") {
        dispatch(logout());
      }
      dispatch({
        type: RESERVA_NO_REVISADAS_NUMBER_FAIL,
        payload: message,
      });
    }
  };

export const getReservaDetails = (codigo) => async (dispatch, getState) => {
  try {
    dispatch({
      type: RESERVA_DETAILS_REQUEST,
    });

    const { data } = await api.get(`/reserva/codigo/${codigo}` /* config */);

    dispatch({
      type: RESERVA_DETAILS_SUCCESS,
      payload: data,
    });

    if (data.revisado === 0) {
      await api.post(`/reserva/revisado`, { codigo: data.codigo });
    }
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    /* if (message === 'Not authorized, token failed') {
        dispatch(logout())
      } */
    dispatch({
      type: RESERVA_DETAILS_FAIL,
      payload: message,
    });
  }
};

export const changeReservaState =
  (id, estado) => async (dispatch, getState) => {
    const estado_reserva = estado === "CONFIRMADO" ? 1 : 2;
    console.log(estado_reserva);
    try {
      dispatch({
        type: RESERVA_CHANGE_STATE_REQUEST,
      });

      /*  const {
      userLogin: { userInfo },
    } = getState(); */

      /*  const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }  */

      const { data } = await api.post(
        `/reserva/estado`,
        { id, estado_reserva } /* , config */
      );

      dispatch({
        type: RESERVA_CHANGE_STATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      /* if (message === 'Not authorized, token failed') {
      dispatch(logout())
    } */
      dispatch({
        type: RESERVA_CHANGE_STATE_FAIL,
        payload: message,
      });
    }
  };

export const deleteReserva = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: RESERVA_ELIMINAR_REQUEST,
    });
    const { data } = await api.delete(
      `/reserva/${id}`
      /* , config */
    );

    dispatch({
      type: RESERVA_ELIMINAR_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    /* if (message === 'Not authorized, token failed') {
      dispatch(logout())
    } */
    dispatch({
      type: RESERVA_ELIMINAR_FAIL,
      payload: message,
    });
  }
};

export const deleteHoarioReserva =
  (codigo, id_horario) => async (dispatch, getState) => {
    // console.log(codigo);
    // console.log(id_horario);
    try {
      dispatch({
        type: RESERVA_ELIMINAR_HORARIO_REQUEST,
      });
      const { data } = await api.delete(
        "/reserva/detalle",
        { data: { codigo: codigo, id_horario: id_horario } }

        /* , config */
      );

      dispatch({
        type: RESERVA_ELIMINAR_HORARIO_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      /* if (message === 'Not authorized, token failed') {
      dispatch(logout())
    } */
      dispatch({
        type: RESERVA_ELIMINAR_HORARIO_FAIL,
        payload: message,
      });
    }
  };
