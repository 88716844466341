import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import LoginPage from "./pages/LoginPage";
import "./App.css";
import "./styles/app.scss";
//import Layout from "./components/Layout/Layout";
import HomePage from "./pages/HomePage";
import Header from "./components/Header/Header";
import ListRecargasPage from "./pages/ListRecargasPage";
import EditarRecargasPage from "./pages/EditarRecargaPage";
import EditarReservaPage from "./pages/EditarReservaPage";
import CalendarioPage from "./pages/CalendarPage";
import ReservasDiaPage from "./pages/ReservasDiapage";
//import Breadcrumbs from "./components/Breadbrumbs/Breadcrumbs";

import s from "./components/Layout/Layout.module.scss";
import Layout from "./components/Layout/Layout";
import HTML5Backend from "react-dnd-html5-backend";
import { DragDropContext as dragDropContext } from "react-dnd";
//reservas page
import CanchasPage from "./pages/CrearReservas/CanchasPage";
import HorariosPage from "./pages/CrearReservas/HorarioPage";

import ListUsersPage from "./pages/ListUsersPage";
import EditUsersPage from "./pages/EditarUsuarioPage";
import CanchasListPage from "./pages/CanchasPage";

//horarios
import HorariosSelectoption from "./pages/GestionHorarios/HorariosSelectOptionPage";
import HorariosPrecios from "./pages/GestionHorarios/PreciosPage";
import HorariosDescuentos from "./pages/GestionHorarios/DescuentosPage";
import EditarPrecioPage from "./pages/GestionHorarios/EditarPrecioPage";
import EditarDescuentosPage from "./pages/GestionHorarios/EditarDescuentoPage";
import HabilitarHorariosPage from "./pages/GestionHorarios/HabilitarCanchas";
import AdminPreciosDescuentosPage from "./pages/GestionHorarios/AdministrarPage";
import EditarPreciosDescuentos from "./pages/GestionHorarios/HorarioEditarPrecioDescuentoPage";
import AgregarPreciosPage from "./pages/GestionHorarios/PreciosAddPage";
import AgregarDescuentosPage from "./pages/GestionHorarios/DescuentosAddPage";

//noticias
import NoticiasListPage from "./pages/Noticias/NoticiasListPage";
import NoticiasAddpage from "./pages/Noticias/NoticiasAddPage";
import NoticiasEditpage from "./pages/Noticias/NoticiasEditPage";

//estadisticicas
import Estadisticaspage from "./pages/EstadisticasPage";

function App() {
  return (
    <div>
      <ToastContainer />
      <Router>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          {/* <Route path="/panel" element={<Layout />} /> */}
          <Route path="/panel" element={<HomePage />} />

          <Route path="/panel/calendario" element={<CalendarioPage />} />
          <Route path="/reserva/editar/:id" element={<EditarReservaPage />} />
          <Route path="/recargas/" element={<ListRecargasPage />} />
          <Route path="/reservas/cancha" element={<CanchasPage />} />
          <Route path="/reservas/horario" element={<HorariosPage />} />
          <Route path="/reservas/search/:keyword" element={<Layout />} />
          <Route path="/reservas/page/:pageNumber" element={<Layout />} />
          <Route
            path="/reservas/search/:keyword/page/:pageNumber"
            element={<Layout />}
          />
          <Route path="/reservas/" element={<Layout />} />
          <Route path="/reservas/:id" element={<ReservasDiaPage />} />
          <Route path="/recargas/editar/:id" element={<EditarRecargasPage />} />
          <Route path="/usuarios" element={<ListUsersPage />} />
          <Route path="/usuario/editar/:id" element={<EditUsersPage />} />
          <Route path="/canchas" element={<CanchasListPage />} />

          {/* Horarios************************** */}

          <Route path="/horarios" element={<HorariosSelectoption />} />
          <Route
            path="/precios/administrar"
            element={<AdminPreciosDescuentosPage />}
          />
          <Route
            path="/precios/:dia/:hora/:precio/:descuento"
            element={<EditarPreciosDescuentos />}
          />
          <Route
            path="/horarios/habilitar"
            element={<HabilitarHorariosPage />}
          />
          <Route path="/horarios/precios" element={<HorariosPrecios />} />
          <Route
            path="/horarios/precios/agregar"
            element={<AgregarPreciosPage />}
          />
          <Route path="/horarios/precios/:id" element={<EditarPrecioPage />} />
          <Route path="/horarios/descuentos" element={<HorariosDescuentos />} />
          <Route
            path="/horarios/descuentos/agregar"
            element={<AgregarDescuentosPage />}
          />
          <Route
            path="/horarios/descuentos/:id"
            element={<EditarDescuentosPage />}
          />
          {/* FIN HORARIOS************************** */}

          <Route path="/noticias" element={<NoticiasListPage />} />
          <Route path="/noticias/agregar" element={<NoticiasAddpage />} />
          <Route path="/noticias/:id" element={<NoticiasEditpage />} />
          <Route path="/estadisticas" element={<Estadisticaspage />} />
          <Route path="/" element={<Navigate to="/panel" />} />
          {/*  <Route path="/panel/principal" element={<HomePage />} />
          <Route path="/" element={<Navigate to="/panel/principal" />} />
          <Route path="/panel" element={<Navigate to="/panel/principal" />} /> */}
        </Routes>
      </Router>
      {/*  <Router>
        <div className={s.root}>
          <div className={s.wrap}>
            <Header />
            <main className={s.content}>
              <Routes>
                <Route path="/panel/principal" element={<HomePage />} />
                <Route path="/" element={<Navigate to="/panel/principal" />} />
                <Route
                  path="/panel"
                  element={<Navigate to="/panel/principal" />}
                />
              </Routes>
            </main>
          </div>
        </div>
      </Router> */}
    </div>
  );
}

export default dragDropContext(HTML5Backend)(App);
