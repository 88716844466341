import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaPenAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
import {
  Col,
  Row,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import Widget from "../../components/Widget/Widget.js";
import { v4 as uuidv4 } from "uuid";
import Header from "../../components/Header/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import Footer from "../../components/Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/shared/Loader";
import Alert from "../../components/shared/Alerts";
// -- Component Styles
import s from "../../components/Layout/Layout.module.scss";
import sTable from "../../components/Tables/Tables.module.scss";
import mock from "../../components/Tables/mock";
import { listNoticias } from "../../redux/actions/noticiasActions";
import { NOTICIAS_DETALLE_RESET } from "../../redux/constants/noticiasConstants";
import api from "../../api/api";
import Seo from "../../components/shared/seo";
const NoticiasListPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [success, setSuccess] = useState(null);
  const [errorDetele, setErrorDetele] = useState(null);
  const [loadingDetele, setLoadingDetele] = useState(null);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const noticiasInfo = useSelector((state) => state.noticiasList);
  const { loading, noticias, error } = noticiasInfo;
  //console.log(location);

  const [secondTable] = useState(mock.secondTable);
  const [secondTableCurrentPage, setSecondTableCurrentPage] = useState(0);

  const pageSize = 5;
  const secondTablePagesCount = noticias
    ? Math.ceil(noticias.length / pageSize)
    : Math.ceil(secondTable.length / pageSize);

  const setSecondTablePage = (e, index) => {
    e.preventDefault();
    setSecondTableCurrentPage(index);
  };

  const handleAgregar = (e) => {
    e.preventDefault();
    navigate("/noticias/agregar");
  };

  const handleEditar = (id) => {
    navigate(`/noticias/${id}`);
  };
  const deleteHandler = (id) => {
    // e.preventDefault();
    if (window.confirm("Desea eliminar esta noticia?")) {
      setLoadingDetele(true);
      setErrorDetele(null);
      const add = async () => {
        try {
          const { data } = await api.delete(`/noticia/${id}`);
          setSuccess(data);
          setLoadingDetele(false);
        } catch (error) {
          setErrorDetele(error.response.data.message);
          setLoadingDetele(false);
        }
      };

      add();
    }
  };

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    }
    dispatch({ type: NOTICIAS_DETALLE_RESET });
    if (success) {
      dispatch(listNoticias());
    }
    dispatch(listNoticias());
  }, [userInfo, navigate, dispatch, success]);

  return (
    <div className={s.root}>
      <Seo
        titlePage="Noticias"
        descriptionPage="Pádel Cuenca. Somos un club conformado por personas amantes del deporte, enfocados en fomentar la vida activa y saludable, priorizando el ejercicio como un hábito que beneficie nuestro estado físico y mental, Disponemos de canchas de Pádel en Cuenca Ecuador para que pueda realizar una reserva."
      />
      <div className={s.wrap}>
        <Header />
        <Sidebar />
        <main className={s.content}>
          <Row>
            <Col>
              <Row className="mb-4">
                <Col>
                  <Widget>
                    <div className={sTable.tableTitle}>
                      <div className="headline-2">Noticias Registradas</div>
                    </div>
                    {error ? (
                      <Alert message={error} severity="error" />
                    ) : loading ? (
                      <div style={{ textAlign: "center" }}>
                        <Loader />
                      </div>
                    ) : (
                      noticias &&
                      (loadingDetele ? (
                        <Loader />
                      ) : errorDetele ? (
                        <Alert message={error} severity="error" />
                      ) : (
                        <div className="widget-table-overflow">
                          {success && (
                            <Alert
                              message="Reserva Eliminada con éxito"
                              severity="success"
                            />
                          )}
                          <Table
                            className="table-striped table-borderless table-hover"
                            responsive
                          >
                            <thead>
                              <tr>
                                {/*  <th>
                              <div className="checkbox checkbox-primary">
                                <input
                                  id="checkbox200"
                                  className="styled"
                                  type="checkbox"
                                />
                                <label for="checkbox200" />
                              </div>
                            </th> */}
                                <th className={s.nameCol}>titulo</th>
                                <th className={s.nameCol}>etiqueta</th>
                                <th className={s.nameCol}>url</th>
                                <th className={s.nameCol}>descripción</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {noticias
                                .slice(
                                  secondTableCurrentPage * pageSize,
                                  (secondTableCurrentPage + 1) * pageSize
                                )
                                .map((item) => (
                                  <tr key={uuidv4()}>
                                    <td>{item.titulo}</td>
                                    <td>{item.etiqueta}</td>
                                    <td> {item.url}</td>
                                    <td>{item.descripcion}</td>
                                    <td>
                                      <button
                                        style={{
                                          background: "#ffc405",
                                          padding: "5px",
                                          color: "white",
                                          border: "2px solid #ffc405",
                                          minWidth: "120px",
                                          margin: "10px 0px",
                                        }}
                                        onClick={() => {
                                          handleEditar(item.id);
                                        }}
                                      >
                                        <span>
                                          <FaPenAlt /> Editar
                                        </span>
                                      </button>
                                      <button
                                        style={{
                                          background: "red",
                                          padding: "5px",
                                          color: "white",
                                          border: "2px solid red",
                                          minWidth: "120px",
                                        }}
                                        onClick={() => {
                                          deleteHandler(item.id);
                                        }}
                                      >
                                        <span>
                                          <FaTrashAlt /> eliminar
                                        </span>
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </Table>
                          <Pagination className="pagination-with-border">
                            <PaginationItem
                              disabled={secondTableCurrentPage <= 0}
                            >
                              <PaginationLink
                                onClick={(e) =>
                                  setSecondTablePage(
                                    e,
                                    secondTableCurrentPage - 1
                                  )
                                }
                                previous
                                href="#top"
                              />
                            </PaginationItem>
                            {[...Array(secondTablePagesCount)].map(
                              (page, i) => (
                                <PaginationItem
                                  active={i === secondTableCurrentPage}
                                  key={i}
                                >
                                  <PaginationLink
                                    onClick={(e) => setSecondTablePage(e, i)}
                                    href="#top"
                                  >
                                    {i + 1}
                                  </PaginationLink>
                                </PaginationItem>
                              )
                            )}
                            <PaginationItem
                              disabled={
                                secondTableCurrentPage >=
                                secondTablePagesCount - 1
                              }
                            >
                              <PaginationLink
                                onClick={(e) =>
                                  setSecondTablePage(
                                    e,
                                    secondTableCurrentPage + 1
                                  )
                                }
                                next
                                href="#top"
                              />
                            </PaginationItem>
                          </Pagination>
                          <div style={{ textAlign: "center" }}>
                            <button
                              className="tg-btn rounded-pill my-3 body-2 "
                              style={{
                                background: "#8dbe22",
                                border: "2px solid #8dbe22",
                                color: "white",
                                marginTop: "5px",
                                padding: "5px 10px",
                                fontSize: "16px",
                              }}
                              onClick={handleAgregar}
                            >
                              <FaPlus /> Agregar
                            </button>
                          </div>
                        </div>
                      ))
                    )}
                  </Widget>
                </Col>
              </Row>
            </Col>
          </Row>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default NoticiasListPage;
