export const NOTICIAS_LIST_REQUEST = "NOTICIAS_LIST_REQUEST";
export const NOTICIAS_LIST_SUCCESS = "NOTICIAS_LIST_SUCCESS";
export const NOTICIAS_LIST_FAIL = "NOTICIAS_LIST_FAIL";
export const NOTICIAS_LIST_RESET = "NOTICIAS_LIST_RESET";

export const NOTICIAS_DETALLE_REQUEST = "NOTICIAS_DETALLE_REQUEST";
export const NOTICIAS_DETALLE_SUCCESS = "NOTICIAS_DETALLE_SUCCESS";
export const NOTICIAS_DETALLE_FAIL = "NOTICIAS_DETALLE_FAIL";
export const NOTICIAS_DETALLE_RESET = "NOTICIAS_DETALLE_RESET";

export const NOTICIAS_ADD_REQUEST = "NOTICIAS_ADD_REQUEST";
export const NOTICIAS_ADD_SUCCESS = "NOTICIAS_ADD_SUCCESS";
export const NOTICIAS_ADD_FAIL = "NOTICIAS_ADD_FAIL";
export const NOTICIAS_ADD_RESET = "NOTICIAS_ADD_RESET";

export const NOTICIAS_DELETE_REQUEST = "NOTICIAS_DELETE_REQUEST";
export const NOTICIAS_DELETE_SUCCESS = "NOTICIAS_DELETE_SUCCESS";
export const NOTICIAS_DELETE_FAIL = "NOTICIAS_DELETE_FAIL";
export const NOTICIAS_DELETE_RESET = "NOTICIAS_DELETE_RESET";

export const NOTICIAS_UPDATE_REQUEST = "NOTICIAS_UPDATE_REQUEST";
export const NOTICIAS_UPDATE_SUCCESS = "NOTICIAS_UPDATE_SUCCESS";
export const NOTICIAS_UPDATE_FAIL = "NOTICIAS_UPDATE_FAIL";
export const NOTICIAS_UPDATE_RESET = "NOTICIAS_UPDATE_RESET";
