// -- React and related libs
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header/Header";
import Sidebar from "../components/Sidebar/Sidebar";
import Footer from "../components/Footer/Footer";
import { useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import Widget from "../components/Widget/Widget";
import ReservasMetodoPagoChart from "../components/Charts/ReservasMetodoPagoChart";
import Card from "../components/shared/Card";
import Alert from "../components/shared/Alerts";
import Loader from "../components/shared/Loader";
import user from "../images/userIcon.png";
import api from "../api/api";

// -- Component Styles
import s from "../components/Layout/Layout.module.scss";
import sDashboard from "../styles/Dashboard.module.scss";
import Seo from "../components/shared/seo";
const EstadisticasPage = () => {
  const [successData, setSuccessData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);
  const navigate = useNavigate();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  //console.log(location);

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    }
    setLoading(true);
    setError(null);
    setSuccessData(null);
    const edit = async () => {
      try {
        const { data } = await api.get("/empresa/estadisticas");
        setSuccessData(data);
        setLoading(false);
      } catch (error) {
        //console.log(error);
        setError("Algo salió mal.");
        setLoading(false);
      }
    };

    edit();
  }, [userInfo, navigate]);

  return (
    <div className={s.root}>
      <Seo
        titlePage="Estadísticas"
        descriptionPage="Pádel Cuenca. Somos un club conformado por personas amantes del deporte, enfocados en fomentar la vida activa y saludable, priorizando el ejercicio como un hábito que beneficie nuestro estado físico y mental, Disponemos de canchas de Pádel en Cuenca Ecuador para que pueda realizar una reserva."
      />
      <div className={s.wrap}>
        <Header />
        <Sidebar />
        <main className={s.content}>
          {error && (
            <div style={{ textAlign: "center" }}>
              {" "}
              <Alert message="Algo salió mal" severity="error" />
            </div>
          )}
          {loading && (
            <div style={{ textAlign: "center" }}>
              {" "}
              <Loader />
            </div>
          )}
          {successData && (
            <Row>
              <Col className="pr-grid-col" xs={12} lg={8}>
                <Row className="gutter mb-4">
                  <Col>
                    <Widget className="widget-p-md">
                      <div className="headline-2 mb-3">
                        Reservas por Método de Pago
                      </div>
                      <ReservasMetodoPagoChart
                        labels={successData.reservasByMetodo.metodos}
                        values={successData.reservasByMetodo.cantidades}
                        title="Método de Pago"
                      />
                    </Widget>
                  </Col>
                </Row>
              </Col>
              <Col className="pl-grid-col pr-grid-col" xs={12} lg={4}>
                <Row className="gutter mb-4 pl-grid-row pr-grid-row">
                  <Col xs={12} xl={12}>
                    {/* <div className="headline-2 mb-3">
                      Número de usuarios registrados
                    </div> */}
                    <Card
                      title="Usuarios Registrados"
                      link="/usuarios"
                      number={successData.usuariosRegistrados}
                    />
                    {/*  <RechartsPieChart/> */}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Widget className="widget-p-md">
                      <div className="headline-2 mb-3">
                        Cliente con más Reservas
                      </div>
                      {/*  <ApexLineChart/> */}
                      <div className="d-flex">
                        <img
                          className={sDashboard.image}
                          src={user}
                          alt="..."
                        />
                        <div className={sDashboard.userInfo}>
                          <p className="headline-3">
                            {successData.reservasByUsuario.usuario}
                          </p>
                          <p className="body-3 muted">
                            {successData.reservasByUsuario.email}
                          </p>
                        </div>
                      </div>
                      <div className={sDashboard.userParams}>
                        <div className="d-flex flex-column">
                          <p className="headline-3">
                            {successData.reservasByUsuario.reservas}
                          </p>
                          <p className="body-3 muted">Reservas</p>
                        </div>
                        <div className="d-flex flex-column">
                          <p className="headline-3">
                            {successData.reservasByUsuario.recargas}
                          </p>
                          <p className="body-3 muted">Recargas</p>
                        </div>
                        <div className="d-flex flex-column">
                          <p className="headline-3">
                            {successData.reservasByUsuario.telefono}
                          </p>
                          <p className="body-3 muted">Teléfono</p>
                        </div>
                      </div>
                    </Widget>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default EstadisticasPage;
