import React, { useEffect, useState } from "react";
import "moment/locale/es";
import api from "../../api/api";
import { Col, Row } from "reactstrap";
import Widget from "../Widget/Widget.js";
import s from "../Tables/Tables.module.scss";
import Loader from "../shared/Loader";
import Message from "../shared/Alerts";
import CalendarData from "./calendarData";
import CalendarComponent from "./CalendarComponent2";

const events = [
  {
    id: 1,
    color: "#fd3153",
    from: "2019-05-02T18:00:00+00:00",
    to: "2019-05-05T19:00:00+00:00",
    title: "This is an event",
  },
  {
    id: 2,
    color: "#1ccb9e",
    from: "2019-05-01T13:00:00+00:00",
    to: "2019-05-05T14:00:00+00:00",
    title: "This is another event",
  },
  {
    id: 3,
    color: "#3694DF",
    from: "2019-05-05T13:00:00+00:00",
    to: "2019-05-05T20:00:00+00:00",
    title: "This is also another event",
  },
];
const CalendarHome = ({ cancha }) => {
  const [horario, setHorario] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(null);
  const handleDateClick = (info) => {
    info.jsEvent.preventDefault();

    if (info.event.url) {
      window.open(info.event.url);
    }
  };
  const calendarioDatos = horario
    ? [
        horario.map((val, i) => ({
          codigo: val.codigo,
          title: val.title,
          start: new Date(val.start),
          end: new Date(val.end),
          type: val.type,
        })),
      ]
    : [{ title: "dffdfdfdf", date: `2021-11-26` }];
  //console.log(calendarioDatos[0]);
  // console.log(CalendarData);
  useEffect(() => {
    const getCalendario = async () => {
      setLoading(true);
      try {
        const { data } = await api.post("/horario/calendario", {
          cancha: cancha,
        });
        setHorario(data);

        setLoading(null);
      } catch (error) {
        setError(true);
        setLoading(null);
      }
    };
    getCalendario();
  }, [setLoading, cancha]);
  return (
    <div>
      <Row>
        <Col>
          <Row className="mb-12">
            <Widget>
              {" "}
              <div>
                <div
                  className="headline-2"
                  style={{
                    textAlign: "center",
                    margin: "10px 0px",
                    fontSize: "26px",
                  }}
                >
                  Calendario de Reservas
                </div>
              </div>
              <div>
                <div
                  className="headline-2"
                  style={{ color: "#8dbe22", textAlign: "center" }}
                >
                  Cancha {cancha}
                </div>
              </div>
              {loading ? (
                <div style={{ textAlign: "center" }}>
                  <Loader />
                </div>
              ) : error ? (
                <Message
                  severity="error"
                  message="Por favor intentelo más tarde"
                />
              ) : (
                calendarioDatos[0].length > 0 && (
                  <div>
                    <CalendarComponent data={calendarioDatos[0]} />
                  </div>
                )
              )}
            </Widget>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default CalendarHome;
