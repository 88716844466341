import {
  RESERVA_PLACE_FAIL,
  RESERVA_PLACE_SUCCESS,
  RESERVA_PLACE_REQUEST,
  RESERVA_PLACE_RESET,
  RESERVA_GET_RESULT_FAIL,
  RESERVA_GET_RESULT_REQUEST,
  RESERVA_GET_RESULT_SUCCESS,
} from "../constants/reservaPlace";

export const reservaPlaceReducer = (state = {}, action) => {
  switch (action.type) {
    case RESERVA_PLACE_REQUEST:
      return {
        loading: true,
        reservaResult: {},
      };
    case RESERVA_PLACE_SUCCESS:
      return {
        loading: false,
        reservaResult: action.payload,
      };
    case RESERVA_PLACE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case RESERVA_PLACE_RESET:
      return {};
    default:
      return state;
  }
};
