import api from "../../api/api";
import {
  COURT_LIST_FAIL,
  COURT_LIST_REQUEST,
  COURT_LIST_SUCESS,
  COURT_CHANGE_STATE_FAIL,
  COURT_CHANGE_STATE_REQUEST,
  COURT_CHANGE_STATE_SUCESS,
} from "../constants/courtConstants";

export const listCourts = () => async (dispatch) => {
  try {
    dispatch({ type: COURT_LIST_REQUEST });

    const { data } = await api.get(`/cancha`);

    dispatch({
      type: COURT_LIST_SUCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: COURT_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const changeStateCourts = (id_cancha, id_estado) => async (dispatch) => {
  //const id_estado = estado === 1 ? 0 : 1;
  //console.log(id_cancha);
  //console.log(id_estado);
  try {
    dispatch({ type: COURT_CHANGE_STATE_REQUEST });

    const { data } = await api.post(`/cancha/estado`, { id_cancha, id_estado });

    dispatch({
      type: COURT_CHANGE_STATE_SUCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: COURT_CHANGE_STATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
