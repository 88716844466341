// -- React and related libs
import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Redirect,
  useLocation,
  useNavigate,
  Link,
} from "react-router-dom";

import Header from "../components/Header/Header";
//import HomePage from "../../pages/HomePage";
import Sidebar from "../components/Sidebar/Sidebar";
import Tables from "../components/Tables/Tables";
import Footer from "../components/Footer/Footer";
import ListRecargasTables from "../components/Tables/RecargasList";
//import Breadcrumbs from "../Breadbrumbs/Breadcrumbs";
import { useDispatch, useSelector } from "react-redux";

// -- Component Styles
import s from "../components/Layout/Layout.module.scss";
import Seo from "../components/shared/seo";
const Layout = () => {
  let location = useLocation();
  const navigate = useNavigate();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  //console.log(location);
  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    }
  }, [userInfo, navigate]);

  return (
    <div className={s.root}>
      <Seo
        titlePage="Recargas"
        descriptionPage="Pádel Cuenca. Somos un club conformado por personas amantes del deporte, enfocados en fomentar la vida activa y saludable, priorizando el ejercicio como un hábito que beneficie nuestro estado físico y mental, Disponemos de canchas de Pádel en Cuenca Ecuador para que pueda realizar una reserva."
      />
      <div className={s.wrap}>
        <Header />
        <Sidebar />
        <main className={s.content}>
          <ListRecargasTables />
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
