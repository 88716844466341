import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  closeSidebar,
  openSidebar,
  changeActiveSidebarItem,
} from "../../redux/actions/navigationActions";
import { Button } from "reactstrap";
import { useLocation } from "react-router-dom";
import s from "./Sidebar.module.scss";
import LinksGroup from "./LinksGroup/LinksGroup.js";
import logo from "../../images/logoPadelAdmin2.svg";
import cn from "classnames";

const Sidebar = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigator = useSelector((state) => state.navigation);
  const reservasRecargasPendientes = useSelector(
    (state) => state.reservasNorevisadas
  );
  const { reservas } = reservasRecargasPendientes;
  const { activeItem = "", ...restProps } = props;

  const [burgerSidebarOpen, setBurgerSidebarOpen] = useState(false);

  useEffect(() => {
    if (navigator.sidebarOpened) {
      setBurgerSidebarOpen(true);
    } else {
      setTimeout(() => {
        setBurgerSidebarOpen(false);
      }, 0);
    }
  }, [navigator.sidebarOpened]);

  return (
    <nav className={cn(s.root, { [s.sidebarOpen]: burgerSidebarOpen })}>
      <header className={s.logo}>
        <img src={logo} />
        <span className={s.title}>PADEL CUENCA</span>
      </header>
      <ul className={s.nav}>
        <LinksGroup
          onActiveSidebarItemChange={(activeItem) =>
            dispatch(changeActiveSidebarItem(activeItem))
          }
          activeItem={navigator.activeItem}
          header="Calendario"
          isHeader
          iconName={<i className={"eva eva-home-outline"} />}
          link="/panel"
          index="dashboard"
          badge={reservas ? reservas.numero + reservas.numeroRecargas : 0}
        />
        <LinksGroup
          onActiveSidebarItemChange={(activeItem) =>
            dispatch(changeActiveSidebarItem(activeItem))
          }
          activeItem={navigator.activeItem}
          header="Estadísticas"
          isHeader
          iconName={<i className={"eva eva-activity"} />}
          link="/estadisticas"
          index="estadisticas"
        />
        <h5 className={s.navTitle}>Reservas</h5>
        <LinksGroup
          onActiveSidebarItemChange={(activeItem) =>
            props.dispatch(changeActiveSidebarItem(activeItem))
          }
          activeItem={navigator.activeItem}
          header="Crear Reservas"
          isHeader
          iconName={<i className={"eva eva-plus"} />}
          link="/reservas/cancha"
          index="3"
        />
        <LinksGroup
          onActiveSidebarItemChange={(activeItem) =>
            props.dispatch(changeActiveSidebarItem(activeItem))
          }
          activeItem={navigator.activeItem}
          header="Ver Reservas"
          isHeader
          iconName={<i className={"eva eva-eye"} />}
          link="/reservas"
          index="3"
        />
        <h5 className={s.navTitle}>Recargas</h5>
        {/* <LinksGroup
          onActiveSidebarItemChange={(activeItem) =>
            props.dispatch(changeActiveSidebarItem(activeItem))
          }
          activeItem={navigator.activeItem}
          header="Crear Recargas"
          isHeader
          iconName={<i className={"eva eva-grid-outline"} />}
          link="/panel"
          index="2"
        /> */}
        <LinksGroup
          onActiveSidebarItemChange={(activeItem) =>
            props.dispatch(changeActiveSidebarItem(activeItem))
          }
          activeItem={navigator.activeItem}
          header="Ver Recargas"
          isHeader
          iconName={<i className={"eva eva-eye"} />}
          link="/recargas/"
          index="2"
        />
        <h5 className={s.navTitle}>Administrar</h5>
        <LinksGroup
          onActiveSidebarItemChange={(activeItem) =>
            props.dispatch(changeActiveSidebarItem(activeItem))
          }
          activeItem={navigator.activeItem}
          header="Horarios"
          isHeader
          iconName={<i className={"eva eva-monitor"} />}
          link="/horarios/habilitar"
          index="4"
        />
        <LinksGroup
          onActiveSidebarItemChange={(activeItem) =>
            props.dispatch(changeActiveSidebarItem(activeItem))
          }
          activeItem={navigator.activeItem}
          header="Precios "
          isHeader
          iconName={<i className={"eva  eva-pricetags"} />}
          link="/horarios"
          index="4"
        />
        <LinksGroup
          onActiveSidebarItemChange={(activeItem) =>
            props.dispatch(changeActiveSidebarItem(activeItem))
          }
          activeItem={navigator.activeItem}
          header="Usuarios"
          isHeader
          iconName={<i className={"eva eva-person"} />}
          link="/usuarios"
          index="4"
        />

        <LinksGroup
          onActiveSidebarItemChange={(activeItem) =>
            props.dispatch(changeActiveSidebarItem(activeItem))
          }
          activeItem={navigator.activeItem}
          header="Canchas"
          isHeader
          iconName={<i className={"eva eva-grid-outline"} />}
          link="/canchas"
          index="4"
        />
        <LinksGroup
          onActiveSidebarItemChange={(activeItem) =>
            props.dispatch(changeActiveSidebarItem(activeItem))
          }
          activeItem={navigator.activeItem}
          header="Noticias"
          isHeader
          iconName={<i className={"eva eva-globe"} />}
          link="/noticias"
          index="5"
        />

        {/*  <LinksGroup
          onActiveSidebarItemChange={activeItem => props.dispatch(changeActiveSidebarItem(activeItem))}
          activeItem={navigator.activeItem}
          header="UI Elements"
          isHeader
          iconName={<i className={'eva eva-cube-outline'}/>}
          link="/template/uielements"
          index="uielements"
          childrenLinks={[
            {
              header: 'Charts', link: '/template/ui-elements/charts',
            },
            {
              header: 'Icons', link: '/template/ui-elements/icons',
            },
            {
              header: 'Google Maps', link: '/template/ui-elements/maps',
            },
          ]}
        /> */}
      </ul>
      <div className="bg-widget d-flex mt-auto ml-1">
        <Button
          className="rounded-pill my-3 body-2 d-none d-md-block"
          type="submit"
          color="secondary-red"
        >
          <a
            href="https://www.padelcuenca.ec/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "white" }}
          >
            {" "}
            Ir a la página web
          </a>
        </Button>
      </div>
    </nav>
  );
};

Sidebar.propTypes = {
  sidebarOpened: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  activeItem: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default Sidebar;
