import React, { useEffect, useState } from "react";
import { FaArrowLeft, FaPencilAlt } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Row, Button, Form, FormGroup } from "reactstrap";
import Header from "../components/Header/Header";
import Sidebar from "../components/Sidebar/Sidebar";
import Footer from "../components/Footer/Footer";
import Loader from "../components/shared/Loader";
import Message from "../components/shared/Alerts";
/* import SnackBar from "../components/shared/Snackbar"; */
import { useDispatch, useSelector } from "react-redux";
import api from "../api/api";
import s from "../components/Layout/Layout.module.scss";
import { getUserDetails } from "../redux/actions/userActions";
import Widget from "../components/Widget/Widget";
import TextField from "@mui/material/TextField";

import { useForm } from "react-hook-form";
import Seo from "../components/shared/seo";
const UsuarioEditpage = () => {
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);
  const { id } = useParams();
  const { register, handleSubmit } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    criteriaMode: "firstError",
    shouldFocusError: true,
  });
  const onSubmit = (data) => {
    setLoading(true);
    setError(null);
    setSuccess(null);
    const edit = async () => {
      try {
        await api.post("/usuario/admin/saldo", {
          email: user.email,
          saldo: data.saldo,
        });
        setSuccess(true);
        setLoading(false);
      } catch (error) {
        //console.log(error);
        setError("Algo salió mal.");
        setLoading(false);
      }
    };

    edit();
  };

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const detalleInfo = useSelector((state) => state.userDetails);
  const { loading: loadingDetalle, error: errorDetalle, user } = detalleInfo;
  const handleRegresar = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    }
    const getDefault = () => {
      if (user) {
      } else {
        dispatch(getUserDetails(id));
      }
    };
    getDefault();
  }, [userInfo, navigate, dispatch, id, user]);

  return (
    <div className={s.root}>
      <Seo
        titlePage="Usuarios"
        descriptionPage="Pádel Cuenca. Somos un club conformado por personas amantes del deporte, enfocados en fomentar la vida activa y saludable, priorizando el ejercicio como un hábito que beneficie nuestro estado físico y mental, Disponemos de canchas de Pádel en Cuenca Ecuador para que pueda realizar una reserva."
      />
      <div className={s.wrap}>
        <Header />

        <Sidebar />
        <main className={s.content}>
          {/*  {message && (
            <SnackBar
              severity="error"
              message="Por favor actualize uno de los valores"
            />
          )} */}
          <Row>
            <Col>
              <button
                className="tg-btn rounded-pill my-3 body-2 "
                style={{
                  background: "#41404B",
                  color: "white",
                  marginTop: "5px",
                  padding: "5px 10px",
                  fontSize: "16px",
                }}
                onClick={handleRegresar}
              >
                <FaArrowLeft /> Regresar
              </button>
              <Row className="mb-12">
                <Col xs={12} xl={12}>
                  <Widget>
                    {" "}
                    <div style={{ minHeight: "300px", padding: "20px" }}>
                      {" "}
                      <div className="headline-2">Editar Usuario</div>
                      {error && (
                        <Message
                          message="Algo salió mal. Por favor intentalo más tarde"
                          severity="error"
                        />
                      )}
                      {loading && (
                        <div style={{ textAlign: "center" }}>
                          {" "}
                          <Loader />
                        </div>
                      )}
                      {success && (
                        <div style={{ textAlign: "center", marginTop: "25px" }}>
                          <Message
                            message="Usuario Actualizado con éxito"
                            severity="success"
                          />
                        </div>
                      )}
                      {errorDetalle ? (
                        <Message
                          message="Algo salió mal. Por favor intentalo más tarde"
                          severity="error"
                        />
                      ) : loadingDetalle ? (
                        <Loader />
                      ) : (
                        user && (
                          <div style={{ marginTop: "25px", maxWidth: "700px" }}>
                            <h6>
                              Nombre:{" "}
                              <span style={{ color: "#8dbe22" }}>
                                {user.nombre} {user.apellido}
                              </span>
                            </h6>
                            <h6>
                              Correo:{" "}
                              <span style={{ color: "#8dbe22" }}>
                                {user.email}
                              </span>
                            </h6>
                            <h6>
                              Teléfono:{" "}
                              <span style={{ color: "#8dbe22" }}>
                                {user.telefono}
                              </span>
                            </h6>
                            <h6>
                              Nombre de Usuario:{" "}
                              <span style={{ color: "#8dbe22" }}>
                                {user.username}
                              </span>
                            </h6>
                            <Form
                              onSubmit={handleSubmit(onSubmit)}
                              style={{ marginTop: "25px" }}
                            >
                              <FormGroup>
                                <TextField
                                  id="outlined-number"
                                  label="Saldo"
                                  type="number"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  style={{
                                    minWidth: "220px",
                                  }}
                                  //rows={1}
                                  {...register("saldo")}
                                  defaultValue={user.saldo}
                                />
                              </FormGroup>

                              <Button
                                className="rounded-pill my-3 body-2 "
                                color="warning"
                                style={{ color: "white" }}
                                type="submit"
                              >
                                <FaPencilAlt /> Editar
                              </Button>
                            </Form>
                          </div>
                        )
                      )}
                    </div>
                  </Widget>
                </Col>
              </Row>
            </Col>
          </Row>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default UsuarioEditpage;
