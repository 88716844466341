import {
  CHANGE_ACTIVE_SIDEBAR_ITEM,
  CLOSE_SIDEBAR,
  OPEN_SIDEBAR,
} from "../constants/navigationConstants";

/* const initialState = {
  sidebarOpened: false,
  activeItem: JSON.parse(localStorage.getItem("staticSidebar"))
    ? window.location.pathname
    : null,
}; */

export function runtime(state = {}, action) {
  switch (action.type) {
    case OPEN_SIDEBAR:
      return Object.assign({}, state, {
        sidebarOpened: true,
      });
    case CLOSE_SIDEBAR:
      return Object.assign({}, state, {
        sidebarOpened: false,
      });
    case CHANGE_ACTIVE_SIDEBAR_ITEM:
      return {
        ...state,
        activeItem: action.activeItem,
      };
    default:
      return state;
  }
}
