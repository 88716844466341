import React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import cancha from "../../images/canchaPadel1.jpg";
import cancha2 from "../../images/canchapadel2.jpg";

const SelectedDayCard = ({ fecha, hora, id }) => {
  //console.log(fecha + "12:00:00");

  const date = new Date(fecha + " 12:00:00");
  //console.log(date);
  return (
    <Card sx={{ display: "flex" }}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ flex: "0.5 0 auto" }}>
          <Typography component="div" variant="h6">
            {date.toLocaleString("default", {
              weekday: "short",
              timeZone: "America/Guayaquil",
            })}
            , {date.getDate()} de{" "}
            {date.toLocaleString("default", { month: "long" })}
          </Typography>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            component="div"
          >
            {hora}
          </Typography>
        </CardContent>
      </Box>
      <CardMedia
        component="img"
        sx={{ height: 130 }}
        image={id === 1 ? cancha : cancha2}
        alt="Cancha Padel Cuenca"
      />
    </Card>
  );
};

export default SelectedDayCard;
