// -- React and related libs
import React, { useEffect, useState } from "react";
import { FaArrowLeft, FaCheck, FaCheckCircle } from "react-icons/fa";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Redirect,
  useLocation,
  useNavigate,
  Link,
} from "react-router-dom";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import "../../styles/dayPickerComponent.css";
import {
  Col,
  Row,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  ButtonDropdown,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Label,
  Badge,
  Alert,
  FormGroup,
  Form,
  Input,
} from "reactstrap";

import Box from "@mui/material/Box";

import Grid from "@mui/material/Grid";
import { pink } from "@mui/material/colors";

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import Loader from "../../components/shared/Loader";
import Message from "../../components/shared/Alerts";
import SnackBar from "../../components/shared/Snackbar";
import Seo from "../../components/shared/seo";
import Header from "../../components/Header/Header";
//import HomePage from "../../pages/HomePage";
import Sidebar from "../../components/Sidebar/Sidebar";
import Tables from "../../components/Tables/Tables";
import Footer from "../../components/Footer/Footer";
//import Breadcrumbs from "../Breadbrumbs/Breadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import Widget from "../../components/Widget/Widget.js";
// -- Component Styles
import { placeReserva as placeReservaAction } from "../../redux/actions/reservaPlaceActions";
import s from "../../components/Layout/Layout.module.scss";

import { listScheduleActions } from "../../redux/actions/scheduleActions";
import { deleteCourtToReserva } from "../../redux/actions/reservasActions";
import SelectedDayCard from "../../components/shared/SelectedDayCard";
import {
  RESERVA_DELETE_USER,
  RESERVA_DELETE_COURT,
} from "../../redux/constants/reservasConstants";
const HorariosPage = () => {
  const [description, setDescription] = useState("");
  const day = new Date()
    .toLocaleString("sv-SE", {
      timeZone: "America/Guayaquil",
    })
    .slice(0, 10);
  const currentDay = new Date();
  const [selectedDay, setSelectedDay] = useStateWithCallbackLazy(currentDay);
  const oneDay = 24 * 60 * 60 * 1000;
  const diffDays = Math.round((selectedDay - currentDay) / oneDay);
  const [finalizarSeleccion, setfinalizarSeleccion] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState([]);
  const dispatch = useDispatch();
  let location = useLocation();
  const navigate = useNavigate();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const reserva = useSelector((state) => state.reservaAdmin);
  const scheduleList = useSelector((state) => state.schedule);
  const { loading, error, schedule } = scheduleList;
  const placeReserva = useSelector((state) => state.placeReserva);
  const {
    loading: loadingPlace,
    reservaResult,
    error: errorPlace,
  } = placeReserva;
  const MONTHS = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Marzo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  const WEEKDAYS_LONG = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miercoles",
    "Jueves",
    "Viernes",
    "Sábado",
  ];
  const WEEKDAYS_SHORT = ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"];
  const modifiersStyles = {
    birthday: {
      color: "white",
      backgroundColor: "#ffc107",
    },
    thursdays: {
      color: "#ffc107",
      backgroundColor: "#fffdee",
    },
    outside: {
      backgroundColor: "white",
    },
  };

  const handleDayClick = (daySelec) => {
    setSelectedDay(daySelec, (current) => {
      dispatch(
        listScheduleActions(
          current.toISOString().slice(0, 10),
          reserva.court.cancha
        )
      );
    });
  };

  const handleRegresar = (e) => {
    e.preventDefault();
    dispatch(deleteCourtToReserva());
    navigate("/reservas/cancha");
  };
  const handleFinalizar = (e) => {
    e.preventDefault();
    if (selectedSchedule.length > 0) {
      //dispatch(getTempCode(selectedSchedule));
      //dispatch(placeReservaAction(reserva, selectedSchedule));
      dispatch(placeReservaAction(reserva, selectedSchedule, description));
    } else {
      setfinalizarSeleccion(!finalizarSeleccion);
    }
  };

  //console.log(location);
  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    }
    if (reserva.court.cancha) {
      //console.log(day);
      dispatch(listScheduleActions(day, reserva.court.cancha));
    } else {
      navigate("/reservas/cancha");
    }
    if ((loadingPlace === false) & (errorPlace === undefined)) {
      dispatch({ type: RESERVA_DELETE_COURT });
      dispatch({ type: RESERVA_DELETE_USER });
      navigate("/reservas/");
    }
  }, [userInfo, navigate, day, dispatch, reserva, errorPlace, loadingPlace]);

  return (
    <div className={s.root}>
      <Seo
        titlePage="Reservas"
        descriptionPage="Pádel Cuenca. Somos un club conformado por personas amantes del deporte, enfocados en fomentar la vida activa y saludable, priorizando el ejercicio como un hábito que beneficie nuestro estado físico y mental, Disponemos de canchas de Pádel en Cuenca Ecuador para que pueda realizar una reserva."
      />
      {finalizarSeleccion && (
        <SnackBar
          severity="error"
          message="Por favor seleccione al menos una fecha para la reserva"
        />
      )}
      <div className={s.wrap}>
        <Header />
        <Sidebar />
        <main className={s.content}>
          <Row>
            <Col>
              <Row>
                <Col>
                  {" "}
                  <div className={s.tableTitle}>
                    <div className="headline-2">Realizar una reserva</div>
                  </div>
                  <div>2/2 Seleccionar Horario</div>
                  {loadingPlace ? (
                    <div style={{ textAlign: "center", marginTop: "30px" }}>
                      <Loader />
                      <h5 style={{ margin: "30px 0px" }}>
                        Por favor espere mientras validamos el horario.
                      </h5>
                    </div>
                  ) : error ? (
                    <Message
                      message="Algo salió mal, por favor intentelo más tarde."
                      severity="error"
                    />
                  ) : (
                    <Row className="mb-6" style={{ marginTop: "30px" }}>
                      <Col xs={12} xl={5}>
                        <Widget>
                          <h6
                            style={{ paddingTop: "15px", paddingLeft: "15px" }}
                          >
                            Por favor seleccione una fecha
                          </h6>
                          <DayPicker
                            onDayClick={handleDayClick}
                            selectedDays={selectedDay}
                            months={MONTHS}
                            weekdaysLong={WEEKDAYS_LONG}
                            weekdaysShort={WEEKDAYS_SHORT}
                            modifiersStyles={modifiersStyles}
                            className="daypicker"
                          />
                        </Widget>
                      </Col>
                      <Col xs={12} xl={7}>
                        <Widget>
                          {schedule.disponible ? (
                            schedule.disponible.length > 0 && diffDays >= 0 ? (
                              <h5
                                style={{
                                  paddingTop: "15px",
                                  paddingLeft: "15px",
                                  textTransform: "uppercase",
                                }}
                              >
                                Disponible{" "}
                              </h5>
                            ) : (
                              <div>
                                <h5
                                  style={{
                                    margin: "15px",
                                    paddingTop: "15px",
                                    paddingLeft: "15px",
                                  }}
                                >
                                  No existen horarios disponibles en este día
                                </h5>
                              </div>
                            )
                          ) : (
                            <div>
                              <h5></h5>
                            </div>
                          )}
                          {loading ? (
                            <Loader />
                          ) : error ? (
                            <Message
                              severity="error"
                              message="Algo salió mal, por favor intentalo mas tarde"
                            />
                          ) : (
                            <>
                              <Box
                                sx={{ flexGrow: 1 }}
                                style={{
                                  paddingTop: "15px",
                                  paddingLeft: "15px",
                                }}
                              >
                                <Grid
                                  container
                                  spacing={{ xs: 2, md: 3 }}
                                  columns={{ xs: 4, sm: 8, md: 12 }}
                                >
                                  {schedule.disponible
                                    ? schedule.disponible.map((disp, i) => (
                                        <Grid item xs={2} sm={4} md={3} key={i}>
                                          {diffDays >= 0 ? (
                                            <div className="horarioCheckBox horarioDisponible">
                                              <FormControlLabel
                                                control={
                                                  <Checkbox
                                                    onChange={(e) => {
                                                      // add to list
                                                      e.preventDefault();
                                                      /*  console.log(
                                                        e.target.checked
                                                      ); */
                                                      if (e.target.checked) {
                                                        setSelectedSchedule([
                                                          ...selectedSchedule,
                                                          {
                                                            fecha: selectedDay
                                                              .toISOString()
                                                              .slice(0, 10),
                                                            hora: disp.hora,
                                                            precio:
                                                              disp.precio -
                                                              disp.descuento,
                                                          },
                                                        ]);
                                                      } else {
                                                        // remove from list
                                                        console.log("remove");
                                                        setSelectedSchedule(
                                                          selectedSchedule.filter(
                                                            (di) =>
                                                              (di.fecha !==
                                                                selectedDay
                                                                  .toISOString()
                                                                  .slice(
                                                                    0,
                                                                    10
                                                                  )) |
                                                              (di.hora !==
                                                                disp.hora)
                                                          )
                                                        );
                                                      }
                                                    }}
                                                    checked={
                                                      selectedSchedule.find(
                                                        (o) =>
                                                          o.fecha ===
                                                            selectedDay
                                                              .toISOString()
                                                              .slice(0, 10) &&
                                                          o.hora === disp.hora
                                                      )
                                                        ? true
                                                        : false
                                                    }
                                                    color="success"
                                                    sx={{
                                                      "& .MuiSvgIcon-root": {
                                                        fontSize: 22,
                                                      },
                                                    }}
                                                  />
                                                }
                                                label={
                                                  <div
                                                    style={{
                                                      fontSize: "14px",
                                                    }}
                                                  >
                                                    <span>
                                                      Hora:{" "}
                                                      {disp.hora.slice(0, 5)}
                                                    </span>

                                                    <p
                                                      style={{
                                                        fontWeight: "700",
                                                      }}
                                                    >
                                                      Precio: ${" "}
                                                      {disp.precio -
                                                        disp.descuento}
                                                    </p>
                                                  </div>
                                                }
                                              />
                                            </div>
                                          ) : null}
                                        </Grid>
                                      ))
                                    : null}
                                </Grid>
                              </Box>
                            </>
                          )}
                          {selectedSchedule.length > 0 ? (
                            <>
                              <h5
                                style={{
                                  marginTop: "35px",
                                  marginBottom: "25px",
                                  paddingTop: "15px",
                                  paddingLeft: "15px",
                                }}
                              >
                                SELECCIONADO
                              </h5>
                              <Box
                                sx={{ flexGrow: 1 }}
                                style={{ marginBottom: "15px" }}
                              >
                                <Grid
                                  container
                                  spacing={{ xs: 2, md: 3 }}
                                  columns={{ xs: 4, sm: 8, md: 12 }}
                                  style={{
                                    paddingTop: "15px",
                                    paddingLeft: "15px",
                                  }}
                                >
                                  {selectedSchedule.map((sel, i) => (
                                    <Grid item xs={4} sm={4} md={6} key={i}>
                                      <SelectedDayCard
                                        fecha={sel.fecha}
                                        hora={sel.hora}
                                      />
                                    </Grid>
                                  ))}
                                </Grid>
                              </Box>

                              <Form>
                                <FormGroup>
                                  <Label
                                    for="descripcionInput"
                                    sm={2}
                                    style={{
                                      fontWeight: "700",
                                      textTransform: "uppercase",
                                    }}
                                  >
                                    Descripción
                                  </Label>
                                  <Col sm={10}>
                                    <Input
                                      id="descripcionInput"
                                      name="text"
                                      type="textarea"
                                      value={description}
                                      onChange={(e) => {
                                        setDescription(e.target.value);
                                      }}
                                    />
                                  </Col>
                                </FormGroup>
                              </Form>
                            </>
                          ) : (
                            <div style={{ margin: "25px 0px" }}>
                              <p
                                style={{
                                  fontSize: "18px",
                                  fontWeight: "600",
                                  paddingTop: "15px",
                                  paddingLeft: "15px",
                                }}
                              >
                                Todavía no existen horarios seleccionados
                              </p>
                            </div>
                          )}
                          <div
                            className="buttonContainer"
                            style={{ paddingTop: "15px", paddingLeft: "15px" }}
                          >
                            <button
                              className="tg-btn rounded-pill my-3 body-2 "
                              style={{
                                background: "#41404B",
                                color: "white",
                                marginTop: "5px",
                                padding: "5px 10px",
                                fontSize: "16px",
                              }}
                              onClick={handleRegresar}
                            >
                              <FaArrowLeft /> Regresar
                            </button>

                            <button
                              className="tg-btn rounded-pill my-3 body-2 "
                              style={{
                                marginTop: "5px",
                                padding: "5px 10px",
                                color: "white",
                                background: "#8dbe22",
                                border: "1px solid #8dbe22",
                                fontSize: "16px",
                              }}
                              onClick={handleFinalizar}
                            >
                              Reservar <FaCheckCircle />
                            </button>
                          </div>
                        </Widget>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default HorariosPage;
