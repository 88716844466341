import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { useNavigate, Link } from "react-router-dom";
import {
  Col,
  Row,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  ButtonDropdown,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Label,
  Badge,
} from "reactstrap";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Widget from "../Widget/Widget.js";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import cloudIcon from "../../images/assets/tables/cloudIcon.svg";
import funnelIcon from "../../images/assets/tables/funnelIcon.svg";
import optionsIcon from "../../images/assets/tables/optionsIcon.svg";
import printerIcon from "../../images/assets/tables/printerIcon.svg";
import searchIcon from "../../images/assets/tables/searchIcon.svg";
import moreIcon from "../../images/assets/tables/moreIcon.svg";

import s from "./Tables.module.scss";
import mock from "./mock.js";

import Loader from "../shared/Loader";
import Alert from "../shared/Alerts";
import { useDispatch, useSelector } from "react-redux";
import {
  listRecargas,
  listRecargasByCodigo,
  listRecargasByEstado,
} from "../../redux/actions/recargasActions";
import SearchBar from "material-ui-search-bar";
import { COURT_CHANGE_STATE_RESET } from "../../redux/constants/courtConstants";
const RecargasList = function () {
  const [searchKey, setSearchKey] = useStateWithCallbackLazy("");
  const dispatch = useDispatch();
  const recargasList = useSelector((state) => state.recargas);
  const { loading, error, recargas: recargasAll } = recargasList;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const recargasFilterData = useSelector((state) => state.recargasFiltradas);
  const { recargas } = recargasFilterData;

  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [firstTable] = useState(mock.firstTable);
  const [secondTable] = useState(mock.secondTable);
  const [transactions, setTransactions] = useState(mock.transactionsWidget);
  const [tasks, setTasks] = useState(mock.tasksWidget);
  const [firstTableCurrentPage, setFirstTableCurrentPage] = useState(0);
  const [secondTableCurrentPage, setSecondTableCurrentPage] = useState(0);
  const [tableDropdownOpen, setTableMenuOpen] = useState(false);

  const pageSize = 10;
  const firstTablePagesCount = Math.ceil(firstTable.length / pageSize);
  const secondTablePagesCount = recargas
    ? Math.ceil(recargas.length / pageSize)
    : Math.ceil(secondTable.length / pageSize);

  const setFirstTablePage = (e, index) => {
    e.preventDefault();
    setFirstTableCurrentPage(index);
  };

  const setSecondTablePage = (e, index) => {
    e.preventDefault();
    setSecondTableCurrentPage(index);
  };

  const toggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const transactionMenuOpen = (id) => {
    setTransactions(
      transactions.map((transaction) => {
        if (transaction.id === id) {
          transaction.dropdownOpen = !transaction.dropdownOpen;
        }
        return transaction;
      })
    );
  };

  const tableMenuOpen = () => {
    setTableMenuOpen(!tableDropdownOpen);
  };

  const toggleTask = (id) => {
    setTasks(
      tasks.map((task) => {
        if (task.id === id) {
          task.completed = !task.completed;
        }
        return task;
      })
    );
  };

  const handleSearch = (codigo) => {
    setSearchKey(codigo, (current) => {
      dispatch(listRecargasByCodigo(current.trim()));
      setSecondTableCurrentPage(0);
    });
  };

  const handleCambioEstado = (estado) => {
    dispatch(listRecargasByEstado(estado));
    setSecondTableCurrentPage(0);
  };

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    } else {
      dispatch(listRecargas());
    }
    dispatch({ type: COURT_CHANGE_STATE_RESET });
  }, [dispatch, navigate, userInfo]);

  return (
    <div>
      <Row>
        <Col>
          <Row className="mb-4">
            <Col>
              <Widget>
                <div className={s.tableTitle}>
                  <div className="headline-2">Recargas Registradas</div>
                  {recargas && (
                    <SearchBar
                      value={searchKey}
                      onCancelSearch={() => handleSearch("")}
                      onChange={(newValue) => handleSearch(newValue)}
                      onRequestSearch={() => handleSearch(searchKey)}
                      placeholder={"Buscar una recarga"}
                    />
                  )}
                  <Dropdown
                    className="d-none d-sm-block"
                    nav
                    isOpen={tableDropdownOpen}
                    toggle={() => tableMenuOpen()}
                  >
                    <DropdownToggle nav>
                      <img
                        className="d-none d-sm-block"
                        src={funnelIcon}
                        alt="Filtrar..."
                      />
                    </DropdownToggle>
                    <DropdownMenu style={{ marginRight: "80px" }}>
                      <DropdownItem onClick={() => handleCambioEstado("Todas")}>
                        <div>Todas</div>
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => handleCambioEstado("No revisadas")}
                      >
                        <div>No revisadas</div>
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => handleCambioEstado("Pendientes de Pago")}
                      >
                        <div>Pendientes de Pago</div>
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => handleCambioEstado("Confirmadas")}
                      >
                        <div>Confirmadas</div>
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
                {error ? (
                  <Alert message={error} severity="error" />
                ) : loading ? (
                  <div style={{ textAlign: "center" }}>
                    <Loader />
                  </div>
                ) : (
                  recargas && (
                    <div className="widget-table-overflow">
                      <Table
                        className="table-striped table-borderless table-hover"
                        responsive
                      >
                        <thead>
                          <tr>
                            {/*  <th>
                              <div className="checkbox checkbox-primary">
                                <input
                                  id="checkbox200"
                                  className="styled"
                                  type="checkbox"
                                />
                                <label for="checkbox200" />
                              </div>
                            </th> */}
                            <th className={s.nameCol}>Leído</th>
                            <th className={s.nameCol}>CóDIGO</th>
                            <th>EMAIL</th>

                            <th>Saldo Usuario</th>

                            <th>ESTADO</th>
                            <th>EDITAR</th>
                          </tr>
                        </thead>
                        <tbody>
                          {recargas
                            .slice(
                              secondTableCurrentPage * pageSize,
                              (secondTableCurrentPage + 1) * pageSize
                            )
                            .map((item) => (
                              <tr key={uuidv4()}>
                                <td>
                                  <div className="checkbox checkbox-primary">
                                    <input
                                      id="checkbox200"
                                      className="styled"
                                      type="checkbox"
                                      disabled
                                      checked={
                                        item.revisado === 0 ? false : true
                                      }
                                    />
                                    <label for="checkbox200" />
                                  </div>
                                </td>
                                <td>{item.client_transaction_id}</td>
                                <td>{item.usuario.email}</td>
                                <td>$ {item.usuario.saldo}</td>

                                <td>
                                  {item.estado_recarga.estado_reserva ===
                                  "PENDIENTE DE PAGO" ? (
                                    <Badge color="secondary-yellow">
                                      {item.estado_recarga.estado_reserva}
                                    </Badge>
                                  ) : item.estado_recarga.estado_reserva ===
                                    "CONFIRMADO" ? (
                                    <Badge color="secondary-cyan">
                                      {item.estado_recarga.estado_reserva}
                                    </Badge>
                                  ) : null}
                                </td>
                                <td>
                                  {" "}
                                  <Link
                                    to={`/recargas/editar/${item.client_transaction_id}`}
                                  >
                                    {" "}
                                    <i className={"eva eva-edit"} />
                                  </Link>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                      <Pagination className="pagination-with-border">
                        <PaginationItem disabled={secondTableCurrentPage <= 0}>
                          <PaginationLink
                            onClick={(e) =>
                              setSecondTablePage(e, secondTableCurrentPage - 1)
                            }
                            previous
                            href="#top"
                          />
                        </PaginationItem>
                        {[...Array(secondTablePagesCount)].map((page, i) => (
                          <PaginationItem
                            active={i === secondTableCurrentPage}
                            key={i}
                          >
                            <PaginationLink
                              onClick={(e) => setSecondTablePage(e, i)}
                              href="#top"
                            >
                              {i + 1}
                            </PaginationLink>
                          </PaginationItem>
                        ))}
                        <PaginationItem
                          disabled={
                            secondTableCurrentPage >= secondTablePagesCount - 1
                          }
                        >
                          <PaginationLink
                            onClick={(e) =>
                              setSecondTablePage(e, secondTableCurrentPage + 1)
                            }
                            next
                            href="#top"
                          />
                        </PaginationItem>
                      </Pagination>
                    </div>
                  )
                )}
              </Widget>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default RecargasList;
