// -- React and related libs
import React, { useEffect, useState } from "react";
import { FaArrowLeft, FaEye, FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import { Col, Row, Button, Form, FormGroup, Input, Label } from "reactstrap";

import Header from "../../components/Header/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
//import Tables from "../components/Tables/Tables";
import Footer from "../../components/Footer/Footer";
import Loader from "../../components/shared/Loader";
import Message from "../../components/shared/Alerts";
//import Breadcrumbs from "../Breadbrumbs/Breadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import api from "../../api/api";
import { RESERVA_ELIMINAR_HORARIO_RESET } from "../../redux/constants/reservasConstants";
// -- Component Styles
import s from "../../components/Layout/Layout.module.scss";
import Widget from "../../components/Widget/Widget";
//import { useNavigate } from 'react-router-dom';
import Seo from "../../components/shared/seo";
const NoticiasAddpage = () => {
  const [titulo, setTitulo] = useState("");
  const [url, setUrl] = useState("");
  const [etiqueta, setEtiqueta] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const handleRegresar = (e) => {
    e.preventDefault();
    navigate(-1);
  };
  const noticiasHandler = (e) => {
    e.preventDefault();
    navigate("/noticias");
  };

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    }
    /* if (success) {
      navigate("/reservas");
    } */

    //dispatch({ type: RESERVA_ELIMINAR_HORARIO_RESET });
    //setEstado(reservaDetails ? reservaDetails.estado : "");
  }, [userInfo, navigate, dispatch]);

  const submitHandler = (e) => {
    //dispatch(changeReservaState(id, estado));
    //dispatch login
    //dispatch(login(email, password));
    e.preventDefault();
    setLoading(true);
    setError(null);
    const add = async () => {
      try {
        const { data } = await api.post("/noticia", {
          titulo,
          url,
          descripcion,
          etiqueta,
        });
        setSuccess(data);
        setLoading(false);
      } catch (error) {
        setError(error.response.data.message);
        setLoading(false);
      }
    };

    add();
  };
  return (
    <div className={s.root}>
      <Seo
        titlePage="Noticias"
        descriptionPage="Pádel Cuenca. Somos un club conformado por personas amantes del deporte, enfocados en fomentar la vida activa y saludable, priorizando el ejercicio como un hábito que beneficie nuestro estado físico y mental, Disponemos de canchas de Pádel en Cuenca Ecuador para que pueda realizar una reserva."
      />
      <div className={s.wrap}>
        <Header />

        <Sidebar />
        <main className={s.content}>
          {/* <Breadcrumbs url={location.pathname} /> */}
          {/* <h5>Reservas Realizadas</h5> */}
          <Row>
            <Col>
              <button
                className="tg-btn rounded-pill my-3 body-2 "
                style={{
                  background: "#41404B",
                  color: "white",
                  marginTop: "5px",
                  padding: "5px 10px",
                  fontSize: "16px",
                }}
                onClick={handleRegresar}
              >
                <FaArrowLeft /> Regresar
              </button>
              <Row className="mb-12">
                <Col xs={12} xl={12}>
                  <Widget>
                    {" "}
                    <div style={{ minHeight: "300px", padding: "20px" }}>
                      {" "}
                      <div className="headline-2">Agregar Noticia</div>
                      {error && (
                        <Message
                          message="Algo salió mal. Por favor intentalo más tarde"
                          severity="error"
                        />
                      )}
                      {loading && (
                        <div style={{ textAlign: "center" }}>
                          {" "}
                          <Loader />
                        </div>
                      )}
                      {success ? (
                        <div style={{ textAlign: "center", marginTop: "25px" }}>
                          <Message
                            message="Noticia Agregada con éxito"
                            severity="success"
                          />
                          <div>
                            <Button
                              className="rounded-pill my-3 body-2 "
                              color="primary"
                              style={{ color: "white" }}
                              type="submit"
                              onClick={(e) => noticiasHandler(e)}
                            >
                              <FaEye /> Ver todas las noticias
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <div style={{ marginTop: "25px", maxWidth: "700px" }}>
                          <Form onSubmit={submitHandler}>
                            <FormGroup>
                              <Label for="exampleEmail">Título</Label>
                              <Input
                                required
                                id="titulo"
                                name="titulo"
                                placeholder="Ingrese el título"
                                type="text"
                                value={titulo}
                                onChange={(e) => {
                                  setTitulo(e.target.value);
                                }}
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label for="examplePassword">Url</Label>
                              <Input
                                required
                                id="url"
                                name="url"
                                placeholder="Ingrese la Url"
                                type="text"
                                value={url}
                                onChange={(e) => {
                                  setUrl(e.target.value);
                                }}
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label for="examplePassword">Etiqueta</Label>
                              <Input
                                required
                                id="etiqueta"
                                name="etiqueta"
                                placeholder="Ingrese la Etiqueta"
                                type="text"
                                value={etiqueta}
                                onChange={(e) => {
                                  setEtiqueta(e.target.value);
                                }}
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label for="examplePassword">Descripción</Label>
                              <Input
                                required
                                id="descripcion"
                                name="descripcion"
                                placeholder="Ingrese la descripción"
                                type="textarea"
                                value={descripcion}
                                onChange={(e) => {
                                  setDescripcion(e.target.value);
                                }}
                              />
                            </FormGroup>
                            <Button
                              className="rounded-pill my-3 body-2 "
                              color="primary"
                              style={{ color: "white" }}
                              type="submit"
                              /* onClick={() => deleteHandler(reservaDetails.codigo)} */
                            >
                              <FaPlus /> Agregar
                            </Button>
                          </Form>
                        </div>
                      )}
                    </div>
                  </Widget>
                </Col>
              </Row>
            </Col>
          </Row>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default NoticiasAddpage;
