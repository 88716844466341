import axios from "axios";

//https://reserva-padelc.herokuapp.com/api/
const apiUrl =
  process.env.NODE_ENV === "production"
    ? "https://padel-cuenca.herokuapp.com/api/"
    : "https://reserva-padelc.herokuapp.com/api/";
const url = apiUrl;

const api = axios.create({
  baseURL: url,
});

export default api;
