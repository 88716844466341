import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineSearch } from "react-icons/ai";
import SearchBar from "material-ui-search-bar";

import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import DirectionsIcon from "@mui/icons-material/Directions";

const Search = ({ type = "reservas" }) => {
  const [keyword, setKeyword] = useState("");
  const navigate = useNavigate();
  const submitHandler = (e) => {
    e.preventDefault();
    if (keyword.trim()) {
      //trim any white space
      navigate(`/${type}/search/${keyword}`);
    } else {
      navigate(`/${type}`);
    }
  };

  const cancelHandler = (e) => {
    //e.preventDefault();
    navigate(`/${type}`);
  };
  return (
    <Paper
      component="form"
      sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: 400 }}
    >
      {/*  <IconButton sx={{ p: "10px" }} aria-label="menu">
        <MenuIcon />
      </IconButton> */}
      <InputBase
        value={keyword}
        onChange={(e) => {
          setKeyword(e.target.value);
        }}
        sx={{ ml: 1, flex: 1 }}
        placeholder={
          type === "reservas" ? "Buscar una reserva" : "Buscar una recarga"
        }
        inputProps={{
          "aria-label":
            type === "reservas" ? "Buscar una reserva" : "Buscar una recarga",
        }}
      />
      <IconButton
        onClick={submitHandler}
        sx={{ p: "10px" }}
        aria-label="search"
      >
        <SearchIcon />
      </IconButton>
      {/*   <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <IconButton color="primary" sx={{ p: "10px" }} aria-label="directions">
        <DirectionsIcon />
      </IconButton> */}
    </Paper>
  );
};

export default Search;
