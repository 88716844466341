import React, { useEffect } from "react";
import { FaArrowLeft, FaCheck } from "react-icons/fa";
import { useNavigate, useParams, Link } from "react-router-dom";

import { Col, Row, Button, Badge } from "reactstrap";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Header from "../components/Header/Header";
import Sidebar from "../components/Sidebar/Sidebar";
import Footer from "../components/Footer/Footer";
import Loader from "../components/shared/Loader";
import Message from "../components/shared/Alerts";
import { useDispatch, useSelector } from "react-redux";
import {
  getRecargaDetails,
  changeRecargaState,
} from "../redux/actions/recargasActions";
import s from "../components/Layout/Layout.module.scss";
import Widget from "../components/Widget/Widget";
import Seo from "../components/shared/seo";
const EditarRecargapage = () => {
  const [estado, setEstado] = React.useState("");

  const handleChange = (event) => {
    setEstado(event.target.value);
  };

  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const recargaDet = useSelector((state) => state.recargaDetails);
  const { recargaDetails, loading, error, message } = recargaDet;
  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    }
    dispatch(getRecargaDetails(id));
  }, [userInfo, navigate, dispatch, id]);
  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(changeRecargaState(id, estado));
  };
  return (
    <div className={s.root}>
      <Seo
        titlePage="Recargas"
        descriptionPage="Pádel Cuenca. Somos un club conformado por personas amantes del deporte, enfocados en fomentar la vida activa y saludable, priorizando el ejercicio como un hábito que beneficie nuestro estado físico y mental, Disponemos de canchas de Pádel en Cuenca Ecuador para que pueda realizar una reserva."
      />
      <div className={s.wrap}>
        <Header />

        <Sidebar />
        <main className={s.content}>
          <Row>
            <Col>
              <Row className="mb-6">
                <Col xs={12} xl={6}>
                  <Widget>
                    {" "}
                    <div style={{ minHeight: "300px", padding: "20px" }}>
                      {" "}
                      <div className="headline-2">Editar Recarga</div>
                      {error && (
                        <Message
                          message="Algo salió mal. Por favor intentalo más tarde"
                          severity="error"
                        />
                      )}
                      {message && (
                        <Message
                          message="Estado cambiado con éxito"
                          severity="success"
                        />
                      )}
                      {loading && (
                        <div style={{ textAlign: "center" }}>
                          {" "}
                          <Loader />
                        </div>
                      )}
                      {recargaDetails && (
                        <div style={{ marginTop: "25px" }}>
                          <h6>
                            Código de recarga:{" "}
                            <span style={{ color: "#8dbe22" }}>
                              {recargaDetails.client_transaction_id}
                            </span>
                          </h6>
                          <h6>
                            Fecha en la que se realizó la recarga:{" "}
                            <span style={{ color: "#8dbe22" }}>
                              {recargaDetails.fecha_recarga}
                            </span>
                          </h6>
                          <h6>
                            Usuario:{" "}
                            <span style={{ color: "#8dbe22" }}>
                              {recargaDetails.usuario.email}
                            </span>
                          </h6>
                          <h6>
                            Saldo Actual:{" "}
                            <span style={{ color: "#8dbe22" }}>
                              $ {recargaDetails.usuario.saldo}
                            </span>
                          </h6>
                          <h6>
                            Estado Actual:{" "}
                            <span style={{ color: "#8dbe22" }}>
                              {recargaDetails.estado_recarga.estado_reserva ===
                              "PENDIENTE DE PAGO" ? (
                                <Badge color="secondary-yellow">
                                  {recargaDetails.estado_recarga.estado_reserva}
                                </Badge>
                              ) : recargaDetails.estado_recarga
                                  .estado_reserva === "CONFIRMADO" ? (
                                <Badge color="secondary-cyan">
                                  {recargaDetails.estado_recarga.estado_reserva}
                                </Badge>
                              ) : null}
                            </span>
                          </h6>
                          <h6 style={{ marginTop: "25px" }}>
                            <p>Cambiar Estado</p>
                            <FormControl component="fieldset">
                              <RadioGroup
                                row
                                aria-label="estado"
                                name="estado"
                                value={estado}
                                onChange={handleChange}
                                //defaultValue={reservaDetails.estado}
                              >
                                <FormControlLabel
                                  value="CONFIRMADO"
                                  control={<Radio />}
                                  label="CONFIRMADO"
                                  labelPlacement="end"
                                />
                                <FormControlLabel
                                  value="PENDIENTE"
                                  control={<Radio />}
                                  label="PENDIENTE"
                                  labelPlacement="end"
                                />
                              </RadioGroup>
                            </FormControl>
                          </h6>
                          <Button
                            className="rounded-pill my-3 body-2 "
                            color="dark"
                            style={{ minWidth: "220px" }}
                          >
                            <Link to="/recargas" style={{ color: "white" }}>
                              <FaArrowLeft /> Regresar
                            </Link>
                          </Button>
                          <Button
                            className="rounded-pill my-3 body-2 "
                            onClick={(e) => submitHandler(e)}
                            color="secondary-red"
                            style={{ minWidth: "220px" }}
                          >
                            <a style={{ color: "white" }}>
                              Confirmar Cambio <FaCheck />
                            </a>
                          </Button>
                        </div>
                      )}
                    </div>
                  </Widget>
                </Col>
              </Row>
            </Col>
          </Row>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default EditarRecargapage;
