import React from "react";
import { Link } from "react-router-dom";
import "../../styles/card.css";

const Card = ({ title, number, link }) => {
  return (
    <div class="card-box bg-blue">
      <div class="inner">
        <h3> {number} </h3>
        <p> {title} </p>
      </div>
      <div class="icon">
        <i class="eva eva-person" aria-hidden="true"></i>
      </div>
      <Link to={link} class="card-box-footer">
        Ver Más <i class="fa fa-arrow-circle-right"></i>
      </Link>
    </div>
  );
};

export default Card;
